import { useIntl } from "react-intl";
import { useRef } from "react";
import { Modal } from "../../common/Modal";

export const ImageDialog = ({ currentImageUrl }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dismissBtnRef = useRef();

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="imageModal"
        title={intl.formatMessage({ id: "receipt" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 d-flex justify-content-end align-items-center">
          <img src={currentImageUrl} alt="Receipt" className="img-fluid" />
        </div>
      </Modal>
    </div>
  );
};
