import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import { useNavigate } from "react-router-dom";
import { ImageDialog } from "./Image";
import { ApproveDialog } from "./Approve";
import { DeclineDialog } from "./Decline";

export const TopupRequestList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [fromPage, setFromPage] = useState("");
  const [itemId, setItemId] = useState("");
  const dispatch = useDispatch();
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "manualtopuprequestlist",
    };

    dispatch(actions.callATMTransactionList(queryParams));
  }, []);

  const handleImageClick = (url) => {
    setCurrentImageUrl(url);
  };
  const handleRefreshClick = () => {
    const queryParams = {
      action: "manualtopuprequestlist",
    };

    dispatch(actions.callATMTransactionList(queryParams));
  };
  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <ImageDialog currentImageUrl={currentImageUrl} />
      <ApproveDialog fromPage={fromPage} itemId={itemId} />
      <DeclineDialog fromPage={fromPage} itemId={itemId} />

      <div className="d-flex justify-content-between">
        <div>
          <h2>{intl.formatMessage({ id: "topupRequest" })}</h2>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleRefreshClick()}
          >
            {/* {intl.formatMessage({ id: "refresh" })} */}
            <i className="bi bi-arrow-clockwise" />
          </button>
        </div>
      </div>

      <div className="member-div d-flex" style={{ margin: "10px auto" }}>
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "date" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "bankDetails" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "receipt" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {transactionState.atmTransactionLists &&
                transactionState.atmTransactionLists.manualTopupLists &&
                transactionState.atmTransactionLists.manualTopupLists.length >
                  0 ? (
                  transactionState.atmTransactionLists.manualTopupLists.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-nowrap">
                          {report.requestdate}
                        </td>
                        <td className="member-cell col-2 text-start">
                          {report.username}
                        </td>

                        <td className="member-cell col-2 text-start">
                          <div>{report.bankname}</div>
                          <div>{report.accountnum}</div>
                          <div>{report.accountname}</div>
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.amount}
                        </td>
                        <td
                          className="member-cell col-1 text-nowrap"
                          style={{
                            maxWidth: "100px",
                            color: "#0000FF",
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#imageModal"
                          onClick={() => handleImageClick(report.imageurl)}
                        >
                          <i
                            className="bi bi-image"
                            style={{ fontSize: "3.5vh" }}
                          />
                        </td>
                        <td className="member-cell col-2 d-flex ">
                          <button
                            className="btn btn-success btn-sm mx-1"
                            data-bs-toggle="modal"
                            data-bs-target="#ApproveModal"
                            onClick={() => {
                              setFromPage("topup");
                              setItemId(report.itemid);
                            }}
                          >
                            <i className="bi bi-check2" />
                          </button>
                          <button
                            className="btn btn-danger btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#DeclineModal"
                            onClick={() => {
                              setFromPage("topup");
                              setItemId(report.itemid);
                            }}
                          >
                            <i className="bi bi-x-lg" />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
