import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const transactionState = {
  atmTransactionLists: null,
  paymentGatewayTransactionLists: null,
  telcoTransactionLists: null,
  withdrawLists: null,
  updateWithdrawLists: null,
  approveWithdrawLists: null,
  updateManualTopupLists: null,
  paymentOnHoldList: null,
  callTopupRespondLists: null,
  withdrawRespondLists: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: transactionState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    atmTransactionListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.atmTransactionLists = action.payload.data ?? {};
    },
    paymentGatewayTransactionListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.paymentGatewayTransactionLists = action.payload.data ?? {};
    },
    telcoTransactionListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.telcoTransactionLists = action.payload.data ?? {};
    },
    withdrawListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.withdrawLists = action.payload.data ?? {};
    },
    updateWithdrawListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateWithdrawLists = action.payload.data ?? {};
    },
    approveWithdrawListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.approveWithdrawLists = action.payload.data ?? {};
    },
    updateManualTopupFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateManualTopupLists = action.payload.data ?? {};
    },
    paymentOnHoldListFetch: (state, action) => {
      state.actionsLoading = false;
      state.paymentOnHoldList = action.payload.data ?? {};
    },
    callTopupRespondListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.callTopupRespondLists = action.payload.data ?? {};
    },
    withdrawRespondListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.withdrawRespondLists = action.payload.data ?? {};
    },
  },
});
