import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { ProjectCreditDialog } from "../../modules/projectCredit/components/ProjectCreditDialog";
import { ProjectCredit } from "../../modules/projectCredit/components/ProjectCredit";

const ProjectCreditContext = createContext();

const ProjectCreditWrapper = () => {
  const value = {};

  return (
    <ProjectCreditContext.Provider value={value}>
      <Routes>
        <Route path="" element={<ProjectCredit />} />
        <Route
          path="/projectCreditWithdraw"
          element={<ProjectCreditDialog />}
        />
      </Routes>
    </ProjectCreditContext.Provider>
  );
};

export { ProjectCreditWrapper };
