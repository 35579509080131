import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./ReportCRUD";
import { reportSlice } from "./ReportSlice";

const { actions } = reportSlice;

export const callAgentReportList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentReportFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkReportList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentDownlineReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentDownlineReportFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkReportList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentDownlineDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentDownlineDetailsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkReportList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const transactionReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.transactionReportFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkReportList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callSummaryReportList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.summaryReportFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: summaryReportFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentDownlineMemberReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkDetailsReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.agentDownlineMemberReportFetch({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: agentDownlineMemberDetails");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callAgentDownlineMemberDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkDetailsReportList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.agentDownlineMemberDetailsFetch({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: callAgentDownlineMemberDetails");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
