import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ProjectCreditAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { useAuth } from "../../auth/components/AuthInit";
import { ProjectCreditForm } from "./ProjectCreditForm";
import { ProjectCreditConst } from "../../../constants/ProjectCreditConst";
import { ProjectCreditHistory } from "./ProjectCreditHistory";
import { useNavigate } from "react-router-dom";

export const ProjectCredit = ({}) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dismissBtnRef = useRef();
  const accountCreditState = useSelector(
    ({ accountCredit }) => accountCredit,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "projectmastercredit",
    };
    dispatch(actions.callMasterCreditList(queryParams)).then((resp) => {
      resp = resp.data;
      {
        resp.lastmessage && (
          <>
            {setSwalProps({
              show: true,
              icon: "info",
              title: intl.formatMessage({ id: "alert" }),
              text: resp.lastmessage,
              onConfirm: () => {
                dismissBtnRef.current.click();
              },
            })}
          </>
        );
      }
    });
  }, []);

  const customCardClass =
    "rounded align-items-center justify-content-spacebetween py-2 px-5";

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "projectCredit" })}</h2>
      </div>
      <div>
        {accountCreditState && accountCreditState.masterCreditList && (
          <div className="d-flex">
            <div
              className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
              style={{
                border: "1px solid grey",
                margin: "1vh 1vh",
              }}
            >
              <div className="py-2">
                {intl.formatMessage({ id: "balance" }) +
                  " : " +
                  accountCreditState.masterCreditList.balance}
              </div>
            </div>
            <div
              className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
              style={{
                border: "1px solid grey",
                margin: "1vh 1vh",
              }}
            >
              <div className="py-2">
                {intl.formatMessage({ id: "withdrawLock" }) +
                  " : " +
                  accountCreditState.masterCreditList.withdrawlock}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex mt-2 mx-2">
        <button
          className="btn btn-primary"
          onClick={() => {
            navigate("/projectCredit/projectCreditWithdraw", {
              state: {
                balance: accountCreditState.masterCreditList.balance,
              },
            });
          }}
        >
          {intl.formatMessage({ id: "withdraw" })}
        </button>
      </div>
    </div>
  );
};
