import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { CopyToClipboard } from "../../../utils/CommonHelper";
import * as actions from "../redux/DashboardAction";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "./dashboard.css";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export default function Dashboard() {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const dispatch = useDispatch();
  const timeZone = "Asia/Kuala_Lumpur";
  const dashboardState = useSelector(
    ({ dashboard }) => dashboard,
    shallowEqual
  );
  useEffect(() => {
    const timeZone = "Asia/Kuala_Lumpur";
    const queryParams = {
      action: "reportanalysis1",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.checkDashboardList(queryParams));
  }, [dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    const queryParams = {
      action: "reportanalysis1",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.checkDashboardList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const customCardClass =
    "rounded align-items-center justify-content-spacebetween py-2 px-5";

  const iconStyle = {
    fontSize: "4vh",
    margin: "0 2vh",
  };

  return (
    <div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>

        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      {dashboardState && dashboardState.dashboardList && (
        <div className="dashboard-container">
          <div className="dashboard-row">
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-person-square" style={iconStyle} />
                <div>{intl.formatMessage({ id: "member" })}</div>
                <div>{dashboardState.dashboardList.memberregister}</div>
              </div>
            </div>
          </div>
          <div className="dashboard-row">
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-wallet2" style={iconStyle} />
                <div>{intl.formatMessage({ id: "totalTopup" })}</div>
                <div>{dashboardState.dashboardList.totaltopup}</div>
              </div>
            </div>
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-person-badge" style={iconStyle} />
                <div>{intl.formatMessage({ id: "topupCount" })}</div>
                <div>
                  {dashboardState.dashboardList.topupcount +
                    "/" +
                    dashboardState.dashboardList.topupmembercount}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboard-row">
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-coin" style={iconStyle} />
                <div>{intl.formatMessage({ id: "manualTopup" })}</div>
                <div>{dashboardState.dashboardList.manualtopup}</div>
              </div>
            </div>

            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-coin" style={iconStyle} />
                <div>{intl.formatMessage({ id: "totalRefund" })}</div>
                <div>{dashboardState.dashboardList.totalrefund}</div>
              </div>
            </div>
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-coin" style={iconStyle} />
                <div>{intl.formatMessage({ id: "paymentGatewayTopup" })}</div>
                <div>{dashboardState.dashboardList.paymentgatewaytopup}</div>
              </div>
            </div>
          </div>
          <div
            className="dashboard-row py-2 my-2"
            style={{
              borderTop: "1px solid grey",
            }}
          >
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-wallet2" style={iconStyle} />
                <div>{intl.formatMessage({ id: "totalWithdraw" })}</div>
                <div>{dashboardState.dashboardList.totalwithdraw}</div>
              </div>
            </div>
            <div className={`dashboard-card ${customCardClass}`}>
              <div className="d-flex flex-column align-items-center">
                <i className="bi bi-person-badge" style={iconStyle} />
                <div>{intl.formatMessage({ id: "withdrawCount" })}</div>
                <div>
                  {dashboardState.dashboardList.withdrawcount +
                    "/" +
                    dashboardState.dashboardList.withdrawmembercount}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
