import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input, Input1, Input2 } from "../../common/control/InputField";
import { ContactConst } from "../../../constants/ContactConst";
import { useState } from "react";

export const AddContactForm = ({ formikRef, saveValues }) => {
  const intl = useIntl();
  const [selectedNav, setSelectedNav] = useState(ContactConst.CHANNELURL);
  return (
    <Formik
      initialValues={{
        channelname: "",
        channelurl: "",
        telno: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        let queryParams = {
          action: "addmastercontact",
          channelname: values.channelname,
          channelurl: values.channelurl,
          telno: values.telno,
        };
        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="d-flex" style={{ width: "100%" }}>
            {Object.entries(ContactConst).map(([key, value]) => (
              <div
                style={{
                  color: selectedNav === value ? "#0080FF" : "",
                  borderBottom:
                    selectedNav === value ? "2px solid #0080FF" : "",
                  padding: "10px",
                  cursor: "pointer",
                }}
                className="text-font3"
                onClick={() => setSelectedNav(value)}
                key={key}
              >
                {intl.formatMessage({ id: value })}
              </div>
            ))}
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "channelname" })}
            </label>
            <Field
              name="channelname"
              component={Input}
              placeholder={intl.formatMessage({ id: "channelname" })}
              autoComplete="off"
              type="text"
            />
          </div>

          {selectedNav === ContactConst.CHANNELURL ? (
            <div className="mb-2">
              <label
                className="mb-2 text-input-label"
                style={{ color: "white" }}
              >
                {intl.formatMessage({ id: "channelurl" })}
              </label>
              <Field
                name="channelurl"
                component={Input1}
                placeholder={intl.formatMessage({ id: "channelurl" })}
                autoComplete="off"
                type="text"
              />
            </div>
          ) : (
            <div className="mb-2">
              <label
                className="mb-2 text-input-label"
                style={{ color: "white" }}
              >
                {intl.formatMessage({ id: "telno" })}
              </label>

              <Field
                name="telno"
                component={Input2}
                placeholder={intl.formatMessage({ id: "telno" })}
                autoComplete="off"
                type="text"
              />
            </div>
          )}
        </Form>
      )}
    </Formik>
  );
};
