import { useIntl } from "react-intl";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/MemberAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal1";
import { EditMemberForm } from "./EditMemberForm";

export const EditMemberDialog = ({
  setSwalProps,
  memberId,
  nickname,
  password,
  isVisible,
  onClose,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const memberState = useSelector(({ member }) => member, shallowEqual);

  const handleClick = () => {
    const queryParams = {
      action: "editmember",
      password: password,
      nickname: nickname,
      memberid: memberId,
    };
    dispatch(actions.editMemberList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "editMemberSuccess" }),
          onConfirm: () => {
            dismissBtnRef.current.click();
            const queryParams = {
              action: "memberdetail",
              memberid: memberId,
            };
            dispatch(actions.callMemberList(queryParams));
            onClose();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="editMemberModal"
        title={intl.formatMessage({ id: "editMember" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isVisible}
        onClose={onClose}
      >
        <div className="p-3 custom-border-bottom">
          {intl.formatMessage({ id: "confirmEdit" })}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-success btn-sm mx-1"
            type="submit"
            onClick={handleClick}
          >
            {intl.formatMessage({ id: "confirm" })}
          </button>
          <button
            className="btn btn-danger btn-sm"
            type="button"
            onClick={handleCancel}
          >
            {intl.formatMessage({ id: "cancel" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
