import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkReportList(params) {
  return authPost(`${baseUrl}/Agent5.ashx`, params);
}

export function checkDetailsReportList(params) {
  return authPost(`${baseUrl}/Agent10.ashx`, params);
}
