import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import DateRangeComp from "../../common/DateRangeComp";
import { formatInTimeZone } from "date-fns-tz";
import { ApproveAndWithdrawConst } from "../../../constants/TopUpAndWithdrawConst";

export const WithdrawRespondList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const timeZone = "Asia/Kuala_Lumpur";
  const [selectedNav, setSelectedNav] = useState("");
  const dispatch = useDispatch();
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "withdrawrespondedlist",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callWithdrawResondList(queryParams));
  }, []);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    const queryParams = {
      action: "withdrawrespondedlist",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callWithdrawResondList(queryParams));
  };

  const filteredReports =
    transactionState.withdrawRespondLists?.withdrawRespondedLists?.filter(
      (report) =>
        !selectedNav ||
        report.responsesstatus.toLowerCase() === selectedNav.toLowerCase()
    ) || [];

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />

      <div className="d-flex justify-content-between">
        <div>
          <h2>{intl.formatMessage({ id: "withdrawHistory" })}</h2>
        </div>
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <select
          className="dropdown respond-Select"
          value={selectedNav}
          onChange={(e) => setSelectedNav(e.target.value)}
        >
          <option value="">{intl.formatMessage({ id: "all" })}</option>
          {Object.entries(ApproveAndWithdrawConst).map(([key, value]) => (
            <option value={value.toLowerCase()} key={key}>
              {intl.formatMessage({ id: value })}
            </option>
          ))}
        </select>
      </div>

      <div className="member-div d-flex" style={{ margin: "10px auto" }}>
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap">
                    {intl.formatMessage({ id: "requestDate" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "fullname" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "bankDetails" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "respondDate" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "status" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "handleBy" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {filteredReports.length > 0 ? (
                  filteredReports.map((request, index) => (
                    <tr key={index} className="member-row1">
                      <td className="member-cell hide-on-mobile col-1">
                        {index + 1}
                      </td>
                      <td className="member-cell col-2 text-nowrap">
                        {request.requestdate}
                      </td>
                      <td className="member-cell col-2 text-nowrap text-start">
                        {request.fullname}
                      </td>
                      <td className="member-cell col-2 text-nowrap text-start">
                        {request.username}
                      </td>
                      <td className="member-cell col-2 text-start">
                        <div>{request.bankname}</div>
                        <div>{request.accountnum}</div>
                      </td>
                      <td className="member-cell col-2 text-end">
                        {request.amount}
                      </td>
                      <td className="member-cell col-3 text-nowrap">
                        <div>{request.responsesdate}</div>
                      </td>
                      <td className="member-cell col-3 text-start">
                        <span
                          style={{
                            color:
                              request.responsesstatus === "APPROVE"
                                ? "green"
                                : "red",
                          }}
                        >
                          {request.responsesstatus}
                        </span>
                      </td>
                      <td className="member-cell col-3 text-start">
                        <div>{request.handlebyusername}</div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
