import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import { useNavigate } from "react-router-dom";
import { ImageDialog } from "./Image";
import { ApproveDialog } from "./Approve";
import { DeclineDialog } from "./Decline";
import { WithdrawUpdateDialog } from "./WithdrawUpdateDialog";
import { CopyToClipboard } from "../../../utils/CommonHelper";
export const WithdrawRequestList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [fromPage, setFromPage] = useState("");
  const [itemId, setItemId] = useState("");
  const dispatch = useDispatch();
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "withdrawrequestlist",
    };

    dispatch(actions.callWithdrawList(queryParams));
  }, []);

  const handleRefreshClick = () => {
    const queryParams = {
      action: "withdrawrequestlist",
    };

    dispatch(actions.callWithdrawList(queryParams));
  };
  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <ImageDialog currentImageUrl={currentImageUrl} />
      <WithdrawUpdateDialog setSwalProps={setSwalProps} itemId={itemId} />
      <ApproveDialog
        setSwalProps={setSwalProps}
        fromPage={fromPage}
        itemId={itemId}
      />
      <DeclineDialog
        setSwalProps={setSwalProps}
        fromPage={fromPage}
        itemId={itemId}
      />
      <div className="d-flex justify-content-between">
        <div>
          <h2>{intl.formatMessage({ id: "withdrawRequest" })}</h2>
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleRefreshClick()}
          >
            {/* {intl.formatMessage({ id: "refresh" })} */}
            <i className="bi bi-arrow-clockwise" />
          </button>
        </div>
      </div>
      <div className="member-div d-flex" style={{ margin: "10px auto" }}>
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap">
                    {intl.formatMessage({ id: "requestDate" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "fullname" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "bankDetails" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "status" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {transactionState.withdrawLists &&
                transactionState.withdrawLists.withdrawRequestLists &&
                transactionState.withdrawLists.withdrawRequestLists.length >
                  0 ? (
                  transactionState.withdrawLists.withdrawRequestLists.map(
                    (request, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-nowrap">
                          {request.requestdate}
                        </td>
                        <td className="member-cell col-2 text-nowrap text-start">
                          {request.fullname}
                        </td>
                        <td className="member-cell col-2 text-nowrap text-start">
                          {request.username}
                        </td>
                        <td className="member-cell col-2 text-start">
                          <div>{request.bankname}</div>
                          <div className="d-flex justify-content-between">
                            <span>{request.accountnum}</span>
                            <i
                              className="bi bi-copy mx-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  sessionStorage.getItem("platform") ===
                                  "mobile"
                                ) {
                                  console.log(
                                    `clipboard: ${request.accountnum}`
                                  );
                                  CopyToClipboard(
                                    request.accountnum,
                                    setSwalProps,
                                    intl
                                  );
                                } else {
                                  CopyToClipboard(
                                    request.accountnum,
                                    setSwalProps,
                                    intl
                                  );
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className="member-cell col-2 text-end">
                          {request.amount}
                        </td>
                        <td className="member-cell col-3 text-start">
                          <div>{request.status}</div>
                        </td>
                        <td className="member-cell col-2 d-flex">
                          {request.status === "request" ? (
                            <>
                              <button
                                className="btn btn-warning btn-sm mx-1"
                                data-bs-toggle="modal"
                                data-bs-target="#WithdrawUpdateModal"
                                onClick={() => {
                                  setItemId(request.itemid);
                                }}
                              >
                                <i className="bi bi-pen" />
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#DeclineModal"
                                onClick={() => {
                                  setFromPage("withdraw");
                                  setItemId(request.itemid);
                                }}
                              >
                                <i className="bi bi-x-lg" />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="btn btn-success btn mx-1"
                                data-bs-toggle="modal"
                                data-bs-target="#ApproveModal"
                                onClick={() => {
                                  setFromPage("withdraw");
                                  setItemId(request.itemid);
                                }}
                              >
                                <i className="bi bi-check2" />
                              </button>
                            </>
                          )}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
