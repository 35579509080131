import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./AgentCRUD";
import { agentSlice } from "./AgentSlice";

const { actions } = agentSlice;

export const callAgentList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgentList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const callAgentDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentDetails(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.callAgentDetailsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: callAgentDetails");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const createAgentList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.addAgentListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: addAgentList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const updateAgentStatus = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentDetails(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.updateAgentFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: updateAgentList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const editAgentList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentDetails(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.editAgentFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: editAgentList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentTopupAndWithdraw = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentDetails(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentTopupAndWithdrawFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: agentTopupAndWithdraw");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callAgentGameList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentDetails(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentGameListFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: callAgentGameList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callAgentFeeChargeList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentCharge(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentFeeChargeFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgentCharge");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const updateAgentFeeCharge = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgentCharge(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.updateAgentFeeChargeFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgentCharge");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callAgent4DList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgent4D(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agent4DFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgent4D");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const setupAgentLottery = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgent4D(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.setupAgent4DFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgent4D");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agent4DConnectList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAgent4D(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agent4DConnectFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkAgent4D");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const updateAgentCreateDownline = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkStatusDownline(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.updateAgentCreateDownlineFetch({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: updateAgentCreateDownlineFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentQuotaTopupAndWithdraw = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.quotaTopupWithdrawFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: quotaTopupWithdrawFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const agentAccConnect = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agentAccConnectFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: agentAccConnectFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callAgent4DPrize = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.agent4DPrizeListFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: agent4DPrizeListFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const updateAgent4DPrize = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.agent4DPrizeListUpdateFetch({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: agent4DPrizeListUpdateFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const shareMemberDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.shareMemberDetailsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: shareMemberDetailsFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const shareAgentDetails = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkAccQuota(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.shareAgentDetailsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: shareAgentDetailsFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
