import { useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./subAgent.css";
import * as actions from "../redux/SubAgentAction";
import { EditSubAgentDialog } from "./EditSubAgentDialog";
import { Switch } from "antd";
import { UpdateSubAgentPermission } from "./UpdateSubAgentPermission";
import { CreateSubAgentDialog } from "./CreateSubAgentDialog";

export const SubAgentList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [agentId, setAgentId] = useState(null);
  const [oldNickname, setOldNickname] = useState(null);
  // const [toggleSwitch, setToggleSwitch] = useState(false);
  const dispatch = useDispatch();
  const subAgentState = useSelector(({ subAgent }) => subAgent, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "subagentlist",
    };
    dispatch(actions.callSubAgentList(queryParams));
  }, []);

  const handleSwitchChange = (checked, agent) => {
    const updateQueryParams = {
      action: "updatesubagentstatus",
      selagentid: agent,
    };
    dispatch(actions.updateSubAgentStatus(updateQueryParams))
      .then(() => {
        const fetchQueryParams = {
          action: "subagentlist",
        };
        dispatch(actions.callSubAgentList(fetchQueryParams));
      })
      .catch((error) => {
        console.error("Error updating sub-agent status:", error);
      });
  };
  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <EditSubAgentDialog
        setSwalProps={setSwalProps}
        agentId={agentId}
        oldNickname={oldNickname}
      />
      <UpdateSubAgentPermission setSwalProps={setSwalProps} agentId={agentId} />
      <CreateSubAgentDialog setSwalProps={setSwalProps} />
      <div>
        <h2>{intl.formatMessage({ id: "subAgentList" })}</h2>
      </div>
      <div
        className="buttonCss"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        {intl.formatMessage({ id: "addSubAgent" })}
        <button
          className="btn btn-primary buttonAdd"
          data-bs-toggle="modal"
          data-bs-target={`#addSubAgentModal`}
        >
          {intl.formatMessage({ id: "addSubAgent" })}
        </button>
      </div>
      <div
        className="d-flex"
        style={{
          overflowX: "auto",
          margin: "10px auto",
        }}
      >
        <div className="agent-container" style={{ overflowX: "auto" }}>
          <table className="agent-table">
            <thead className="agent-header">
              <tr className="agent-row">
                <th className="agent-cell hide-on-mobile col-1">
                  {intl.formatMessage({ id: "no" })}
                </th>
                <th className="agent-cell col-2 fixed-column">
                  {intl.formatMessage({ id: "username" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "nickname" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "status" })}
                </th>
                <th className="agent-cell col-2">
                  {intl.formatMessage({ id: "action" })}
                </th>
              </tr>
            </thead>
            <tbody className="agent-body">
              {subAgentState.subAgentLists &&
              subAgentState.subAgentLists.subAgentLists ? (
                subAgentState.subAgentLists.subAgentLists.length > 0 ? (
                  subAgentState.subAgentLists.subAgentLists.map(
                    (subagent, index) => (
                      <tr key={index} className="agent-row">
                        <td className="agent-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="agent-cell col-2 fixed-column">
                          {subagent.username}
                        </td>
                        <td className="agent-cell col-2">
                          {subagent.nickname}
                        </td>
                        <td className="agent-cell col-2">
                          {" "}
                          <Switch
                            checked={subagent.status === "active"}
                            onChange={(checked) =>
                              handleSwitchChange(checked, subagent.agentid)
                            }
                          />
                        </td>
                        <td className="agent-cell col-2 d-flex">
                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target={`#editSubAgentModal`}
                            style={{ marginRight: "3px" }}
                            onClick={() => {
                              setAgentId(subagent.agentid);
                              setOldNickname(subagent.nickname);
                            }}
                          >
                            <i class="bi bi-pen"></i>
                          </button>

                          <button
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target={`#updateSubAgentPermissionModal`}
                            style={{ marginLeft: "3px" }}
                            onClick={() => {
                              setAgentId(subagent.agentid);
                            }}
                          >
                            <i class="bi bi-gear"></i>
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr className="oRecordDiv">
                    <td colSpan="5">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="5">
                    <Skeleton height="600px" className="w-100" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
