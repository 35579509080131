import { Formik, Form } from "formik";
import { useIntl } from "react-intl";
import { ErrorContainer } from "../../common/control/InputField";
import { CheckIsNull } from "../../../utils/CommonHelper";
import { useState } from "react";

export const WithdrawApproveForm = ({
  formikRef,
  saveValues,
  clearFileInputRef,
}) => {
  const intl = useIntl();
  const [receipt, setReceipt] = useState();
  const [receiptTouched, setReceiptTouched] = useState(false);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    setReceipt(file);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize={true}
      initialValues={{ receipt: "" }}
      onSubmit={(values, formikHelpers) => {
        if (!CheckIsNull(receipt)) {
          const queryParams = {
            receipt: receipt,
          };
          saveValues(queryParams);
        } else {
          setReceiptTouched(true);
        }
      }}
    >
      {({ values, setFieldValue, touched, errors, setFieldError }) => (
        <Form>
          <div className="p-3 custom-border-bottom">
            <input
              name="image_chooser"
              type="file"
              className="form-control text-font2"
              placeholder={intl.formatMessage({ id: "receipt" })}
              autoComplete="off"
              accept="image/*"
              onChange={handleFileChange}
              onClick={() => setReceiptTouched(true)}
              ref={clearFileInputRef}
            />
            <ErrorContainer
              isShow={receiptTouched && CheckIsNull(receipt)}
              errorMsg={intl.formatMessage({ id: "receiptRequired" })}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
