import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/PlatfromSettingAction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { DeleteDialog } from "./Delete";
import { AddTelegramDialog } from "./AddTelegram";
import { CopyToClipboard, toAbsoluteUrl } from "../../../utils/CommonHelper";

export const TelegramList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [currentIndex, setCurrentIndex] = useState("");
  const [fromSide, setFromSide] = useState("");
  const [platformSettingPermission, setPlatformSettingPermission] =
    useState("");
  const dispatch = useDispatch();
  const platformSettingState = useSelector(
    ({ platformSetting }) => platformSetting,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "telegramlist",
    };
    dispatch(actions.callTelegramList(queryParams));
  }, []);

  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );

    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "platformsetting":
            setPlatformSettingPermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);

  const customCardClass =
    "rounded align-items-center justify-content-spacebetween py-2 px-5";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <AddTelegramDialog setSwalProps={setSwalProps} />
      <DeleteDialog
        setSwalProps={setSwalProps}
        currentIndex={currentIndex}
        fromSide={fromSide}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>{intl.formatMessage({ id: "telegramNoti" })}</h2>
        {platformSettingPermission === "true" && (
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target={`#addTelegramModal`}
          >
            {intl.formatMessage({ id: "addTelegram" })}
          </button>
        )}
      </div>
      <div>
        <img
          src={toAbsoluteUrl(`/image/telegram.png`)}
          style={{
            maxWidth: "300px",
            maxHeight: "350px",
            minHeight: "150px",
            minWidth: "100px",
          }}
        />
        <div>
          <span
            className="mx-2  text-decoration-underline"
            onClick={() => {
              if (sessionStorage.getItem("platform") === "mobile") {
                console.log(`clipboard: https://t.me/st8admin_bot`);
                CopyToClipboard(
                  "https://t.me/st8admin_bot",
                  setSwalProps,
                  intl
                );
              } else {
                CopyToClipboard(
                  "https://t.me/st8admin_bot",
                  setSwalProps,
                  intl
                );
              }
            }}
          >
            https://t.me/st8admin_bot
          </span>
          <i
            className="bi bi-copy mx-1"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (sessionStorage.getItem("platform") === "mobile") {
                console.log(`clipboard: https://t.me/st8admin_bot`);
                CopyToClipboard(
                  "https://t.me/st8admin_bot",
                  setSwalProps,
                  intl
                );
              } else {
                CopyToClipboard(
                  "https://t.me/st8admin_bot",
                  setSwalProps,
                  intl
                );
              }
            }}
          />
        </div>
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        {platformSettingState.telegramList &&
        platformSettingState.telegramList.telegramLists &&
        platformSettingState.telegramList.telegramLists.length > 0 ? (
          platformSettingState.telegramList.telegramLists.map((tele, index) => (
            <div
              className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
              style={{
                border: "1px solid grey",
                margin: "1vh 1vh",
              }}
              key={index}
            >
              <div className="py-2">{tele.receiveid}</div>
              {platformSettingPermission === "true" && (
                <div className="py-2">
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target={`#deleteModal`}
                    onClick={() => {
                      setCurrentIndex(tele.receiveid);
                      setFromSide("telegram");
                    }}
                  >
                    {intl.formatMessage({ id: "delete" })}
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <div
            className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
            style={{
              border: "1px solid grey",
              margin: "1vh 1vh",
            }}
          >
            {platformSettingPermission === "true" ? (
              <>
                <div
                  className="no-record-wrapper"
                  data-bs-toggle="modal"
                  data-bs-target={`#addTelegramModal`}
                >
                  <i className="bi bi-plus fs-1 mb-3" />
                  <div className="text-font2">
                    {intl.formatMessage({ id: "addTelegram" })}
                  </div>
                </div>
              </>
            ) : (
              <div className="no-record-wrapper oRecordDiv">
                <i className="bi bi-search fs-1 mb-3" />
                <div className="text-font2">
                  {intl.formatMessage({ id: "noRecordFound" })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
