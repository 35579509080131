import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./MemberReport.css";
import * as actions from "../redux/MemberAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays } from "date-fns";
import { MemberReportConst } from "../../../constants/MemberReportConst";
import { CopyToClipboard } from "../../../utils/CommonHelper";
import SingleInputDateTimeRangeField from "../../common/DateTimeRangeComp";
import moment from "moment";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { formatInTimeZone } from "date-fns-tz";

export const MemberReportList = () => {
  const { memberId } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const [fromDate, setFromDate] = useState(
    location.state?.fromDate ? new Date(location.state.fromDate) : new Date()
  );
  const [toDate, setToDate] = useState(
    location.state?.fromDate ? new Date(location.state.toDate) : new Date()
  );
  const now = new Date();
  const [fromDateTime, setFromDateTime] = useState(
    new Date(now.setHours(0, 0, 0, 0))
  );
  const [toDateTime, setToDateTime] = useState(
    new Date(now.setHours(23, 59, 59, 999))
  );
  const [pathFromSearch, setPathFromSearch] = useState("");
  const [initialUsername, setInitialUsername] = useState("");
  const [initialAccUsername, setInitialAccUsername] = useState("");
  const [backToPagination, setBackToPagination] = useState(0);
  const [scrollPosition, setScrollPosition] = useState("");
  const [searchUsername, setSearchUsername] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [currentAgentId, setCurrentAgentId] = useState("");
  const [currentAgent, setCurrentAgent] = useState("");
  const [currentDownline, setCurrentDownline] = useState("");
  const [currentUsername, setCurrentUsername] = useState("");
  const [selectedNav, setSelectedNav] = useState(MemberReportConst.ES3);
  const [currentESPage, setCurrentESPage] = useState(1);
  const [currentGatewayPage, setCurrentGatewayPage] = useState(1);
  const [currentTelcoPage, setCurrentTelcoPage] = useState(1);
  const [currentVBossPage, setCurrentVBossPage] = useState(1);
  const [fromPage, setFromPage] = useState("");
  const [agentIDUpline, setAgentIDUpline] = useState("");
  const memberState = useSelector(({ member }) => member, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";

  useEffect(() => {
    const initialUsername = location.state?.userName;
    const initialAccUsername = location.state.state?.userName;
    const pathFromSearch = location.state?.searchName;
    const backToPagination = location.state.state?.pagination;
    const scrollPosition = location.state?.scrollPosition || 0;
    const searchUsername = location.state?.searchUsername;
    const currentAgentId = location.state.state?.currentAgentId;
    const currentUsername = location.state.state?.currentUsername;
    const fromDate = location.state?.fromDate
      ? new Date(location.state.fromDate)
      : new Date();
    const toDate = location.state?.toDate
      ? new Date(location.state.toDate)
      : new Date();
    const fromDateTime = location.state?.fromDate
      ? new Date(location.state.fromDate.setHours(0, 0, 0, 0))
      : new Date(now.setHours(0, 0, 0, 0));
    const toDateTime = location.state?.toDate
      ? new Date(location.state.toDate.setHours(23, 59, 59, 999))
      : new Date(now.setHours(23, 59, 59, 999));
    const fromPage = location.state?.fromPage;
    const agentIDUpline = location.state?.currentAgentId;
    const currentAgent = location.state?.currentAgent;
    const currentDownline = location.state?.currentDownline;
    setInitialUsername(initialUsername);
    setPathFromSearch(pathFromSearch);
    setBackToPagination(backToPagination - 1);
    setScrollPosition(scrollPosition);
    setSearchUsername(searchUsername);
    setInitialAccUsername(initialAccUsername);
    setCurrentAgentId(currentAgentId);
    setCurrentUsername(currentUsername);
    setFromDate(fromDate);
    setToDate(toDate);
    setFromDateTime(fromDateTime);
    setToDateTime(toDateTime);
    setFromPage(fromPage);
    setAgentIDUpline(agentIDUpline);
    setCurrentAgent(currentAgent);
    setCurrentDownline(currentDownline);
    const queryParams = {
      action: "memberes3report",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    const queryParams1 = {
      action: "membergatewayreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    const queryParams2 = {
      action: "membertelcoreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    const formattedFromDateTime =
      moment(fromDateTime).format("YYYY-MM-DD HH:mm");
    const formattedToDateTime = moment(toDateTime).format("YYYY-MM-DD HH:mm");
    const queryParams3 = {
      action: "membergamelogreport",
      selmemberid: memberId,
      companycode: selectedCompany,
      fromdate: formattedFromDateTime,
      todate: formattedToDateTime,
    };
    const queryParams4 = {
      action: "membervbossreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callMemberReportList(queryParams));
    dispatch(actions.callMemberGatewayList(queryParams1));
    dispatch(actions.callMemberTelcoList(queryParams2));
    dispatch(actions.callMemberGameLogReport(queryParams3));
    dispatch(actions.callVBossReport(queryParams4));
  }, [memberId, dispatch, initialUsername]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchButtonClick = () => {
    const queryParams = {
      action: "memberes3report",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    const queryParams1 = {
      action: "membergatewayreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    const queryParams2 = {
      action: "membertelcoreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    const queryParams4 = {
      action: "membervbossreport",
      memberid: memberId,
      pagenum: "0",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callMemberReportList(queryParams));
    dispatch(actions.callMemberGatewayList(queryParams1));
    dispatch(actions.callMemberTelcoList(queryParams2));
    dispatch(actions.callVBossReport(queryParams4));
  };

  const handleDateTimeChange = (start, end) => {
    setFromDateTime(start);
    setToDateTime(end);
  };
  const handleSearchClick = () => {
    const formattedFromDateTime =
      moment(fromDateTime).format("YYYY-MM-DD HH:mm");
    const formattedToDateTime = moment(toDateTime).format("YYYY-MM-DD HH:mm");
    const queryParams = {
      action: "membergamelogreport",
      selmemberid: memberId,
      companycode: selectedCompany,
      fromdate: formattedFromDateTime,
      todate: formattedToDateTime,
    };
    dispatch(actions.callMemberGameLogReport(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const getStatusClass = (status) => {
    switch (status.toLowerCase()) {
      case "reject":
        return "bg-danger text-white";
      case "pending":
        return "bg-warning text-dark";
      case "approve":
        return "bg-success text-white";
      default:
        return "";
    }
  };

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    const formattedFromDateTime =
      moment(fromDateTime).format("YYYY-MM-DD HH:mm");
    const formattedToDateTime = moment(toDateTime).format("YYYY-MM-DD HH:mm");
    const queryParams = {
      action: "membergamelogreport",
      selmemberid: memberId,
      companycode: event.target.value,
      fromdate: formattedFromDateTime,
      todate: formattedToDateTime,
    };
    dispatch(actions.callMemberGameLogReport(queryParams));
  };

  const customCardClass =
    "rounded d-flex align-items-center justify-content-spacebetween py-2";

  const handlePageClick = (pageNumber) => {
    setCurrentESPage(pageNumber);
    const queryParams = {
      action: "memberes3report",
      memberid: memberId,
      pagenum: pageNumber - 1,
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callMemberReportList(queryParams));
  };
  const handlePageClick1 = (pageNumber) => {
    setCurrentGatewayPage(pageNumber);
    const queryParams = {
      action: "membergatewayreport",
      memberid: memberId,
      pagenum: pageNumber - 1,
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callMemberGatewayList(queryParams));
  };
  const handlePageClick2 = (pageNumber) => {
    setCurrentTelcoPage(pageNumber);
    const queryParams = {
      action: "membertelcoreport",
      memberid: memberId,
      pagenum: pageNumber - 1,
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callMemberTelcoList(queryParams));
  };

  const handlePageClick4 = (pageNumber) => {
    setCurrentVBossPage(pageNumber);
    const queryParams = {
      action: "membervbossreport",
      memberid: memberId,
      pagenum: pageNumber - 1,
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callVBossReport(queryParams));
  };
  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex align-items-center">
        <h2>
          <i
            className="bi bi-chevron-left"
            onClick={() => {
              if (pathFromSearch == "yes") {
                navigate("/searchByUsername", {
                  state: {
                    searchUsername: searchUsername,
                  },
                });
                setPathFromSearch("");
              } else if (fromPage == "yes") {
                navigate(`/agentReport/agentDownlineReport/${agentIDUpline}`, {
                  state: {
                    fromDate: fromDate,
                    toDate: toDate,
                    backTo: "memberReport",
                    currentAgent: currentAgent,
                    currentDownline: currentDownline,
                  },
                });
                setFromPage("");
              } else {
                navigate(`/accountList`, {
                  state: {
                    returnAgentId: currentAgentId,
                    backToPagination: backToPagination,
                    scrollPosition: scrollPosition,
                    returnUsername: currentUsername,
                  },
                });
              }
            }}
          ></i>
        </h2>
        <h2>{intl.formatMessage({ id: "memberReport" })}</h2>
      </div>
      {pathFromSearch === "yes" ? (
        <h4>{initialUsername}</h4>
      ) : fromPage === "yes" ? (
        <h4>{initialUsername}</h4>
      ) : (
        <h4>{initialAccUsername}</h4>
      )}
      <div className="d-flex">
        {Object.entries(MemberReportConst).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "#0080FF" : "",
              borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3"
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>
      <div>
        <div>
          {selectedNav === "gamelog" ? (
            <div className="d-flex justify-content-between">
              <SingleInputDateTimeRangeField
                startDateTime={fromDateTime}
                endDateTime={toDateTime}
                onChange={handleDateTimeChange}
              />
              <div style={{ margin: "auto 0" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    handleSearchClick();
                  }}
                >
                  {intl.formatMessage({ id: "search" })}
                </button>
              </div>
            </div>
          ) : (
            <div className="dateSearch-div">
              <div className="dateRange-div">
                <DateRangeComp
                  initialRange={[
                    { startDate: fromDate, endDate: toDate, key: "selection" },
                  ]}
                  onDateChange={handleDateChange}
                />
              </div>
              <div>
                <button
                  className="btn btn-primary"
                  onClick={() => handleSearchButtonClick()}
                >
                  {intl.formatMessage({ id: "search" })}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {selectedNav === MemberReportConst.ES3 ? (
          <>
            {memberState && memberState.memberReportLists && (
              <div
                className={`${customCardClass} gamelistDiv1 cursor-pointer position-relative text-center mobile-overflow`}
                style={{
                  border: "1px solid grey",
                  padding: "2px",
                  margin: "10px 0 ",
                }}
              >
                <div style={{ width: "100%" }}>
                  <table
                    className="text-start table-report"
                    style={{
                      width: "100%",
                      minWidth: "1000px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td className="col-3  td-line">
                          {intl.formatMessage({ id: "totalTopup" }) +
                            " : " +
                            memberState.memberReportLists.totaltopup}
                        </td>
                        <td className="col-3 td-line">
                          {intl.formatMessage({ id: "totalWithdraw" }) +
                            " : " +
                            memberState.memberReportLists.totalwithdraw}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        ) : selectedNav === MemberReportConst.VBOSS ? (
          <>
            {memberState && memberState.vBossReport && (
              <div
                className={`${customCardClass} gamelistDiv1 cursor-pointer position-relative text-center mobile-overflow`}
                style={{
                  border: "1px solid grey",
                  padding: "2px",
                  margin: "10px 0 ",
                }}
              >
                <div style={{ width: "100%" }}>
                  <table
                    className="text-start table-report"
                    style={{
                      width: "100%",
                      minWidth: "1000px",
                    }}
                  >
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                        }}
                      >
                        <td className="col-3  td-line">
                          {intl.formatMessage({ id: "totalTopup" }) +
                            " : " +
                            memberState.vBossReport.totaltopup}
                        </td>
                        <td className="col-3 td-line">
                          {intl.formatMessage({ id: "totalWithdraw" }) +
                            " : " +
                            memberState.vBossReport.totalwithdraw}
                        </td>
                      </tr>
                      <tr>
                        <td className="col-3  td-line">
                          {intl.formatMessage({ id: "totalBuy" }) +
                            " : " +
                            (memberState.vBossReport.totalbuy === null
                              ? "0.00"
                              : memberState.vBossReport.totalbuy)}
                        </td>
                        <td className="col-3 td-line">
                          {intl.formatMessage({ id: "totalWin" }) +
                            " : " +
                            (memberState.vBossReport.totalwin === null
                              ? "0.00"
                              : memberState.vBossReport.totalwin)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div
        className="member-div"
        style={{
          margin: "10px auto",
        }}
      >
        {selectedNav === MemberReportConst.ES3 ? (
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "date" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "beforeAmount" })}
                    </th>
                    <th className="member-cell col-2 text-end">
                      {intl.formatMessage({ id: "amount" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "afterAmount" })}
                    </th>
                    <th className="member-cell col-3 text-start">
                      {intl.formatMessage({ id: "action" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {memberState.memberReportLists?.memberEs3Reports ? (
                    memberState.memberReportLists.memberEs3Reports.length >
                    0 ? (
                      memberState.memberReportLists.memberEs3Reports.map(
                        (member, index) => (
                          <tr key={index} className="member-row1">
                            <td className="member-cell hide-on-mobile col-1">
                              {index + 1}
                            </td>
                            <td className="member-cell col-2">
                              {member.reportdate}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.bfamount}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.amount}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.afamount}
                            </td>
                            <td className="member-cell col-3 text-start">
                              {member.message}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="no-record-wrapper oRecordDiv">
                            <i className="bi bi-search fs-1 mb-3" />
                            <div className="text-font2 ">
                              {intl.formatMessage({ id: "noRecordFound" })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <Skeleton height="600px" className="w-100" />
                      </td>
                    </tr>
                  )}
                </tbody>
                <div className="pagination-container">
                  {memberState &&
                    memberState.memberReportLists &&
                    Array.from(
                      { length: memberState.memberReportLists.totalpage },
                      (_, i) => i + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`pagination-button ${
                          currentESPage === pageNumber ? "active" : ""
                        }`}
                        onClick={() => handlePageClick(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                </div>
              </table>
            </div>
          </div>
        ) : selectedNav === MemberReportConst.GATEWAY ? (
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "requestDate" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "respondDate" })}
                    </th>
                    <th className="member-cell col-2 text-end">
                      {intl.formatMessage({ id: "amount" })}
                    </th>
                    <th className="member-cell col-3 text-start">
                      {intl.formatMessage({ id: "bankDetails" })}
                    </th>
                    <th className="member-cell col-2 text-start">
                      {intl.formatMessage({ id: "checkId" })}
                    </th>
                    <th className="member-cell col-2 text-start">
                      {intl.formatMessage({ id: "status" })}
                    </th>
                    <th className="member-cell col-2 text-nowrap text-start">
                      {intl.formatMessage({ id: "gatewayStatus" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {memberState.memberGatewayLists?.memberGatewayReports ? (
                    memberState.memberGatewayLists.memberGatewayReports.length >
                    0 ? (
                      memberState.memberGatewayLists.memberGatewayReports.map(
                        (gateway, index) => (
                          <tr key={index} className="member-row1">
                            <td className="member-cell hide-on-mobile col-1">
                              {index + 1}
                            </td>
                            <td className="member-cell col-2 text-nowrap">
                              {gateway.requestdate}
                            </td>
                            <td className="member-cell col-2 text-nowrap">
                              {gateway.responsedate}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {gateway.amount}
                            </td>
                            <td className="member-cell col-3 text-start text-nowrap">
                              <div className="d-flex align-items-center ">
                                {gateway.gatewayid}
                                <i
                                  className="bi bi-copy mx-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      sessionStorage.getItem("platform") ===
                                      "mobile"
                                    ) {
                                      console.log(
                                        `clipboard: ${gateway.gatewayid}`
                                      );
                                      CopyToClipboard(
                                        gateway.gatewayid,
                                        setSwalProps,
                                        intl
                                      );
                                    } else {
                                      CopyToClipboard(
                                        gateway.gatewayid,
                                        setSwalProps,
                                        intl
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div>{gateway.gatewayname}</div>
                              <div>{gateway.bankname}</div>
                            </td>
                            <td className="member-cell col-2 text-start ">
                              {gateway.checkid}
                            </td>
                            <td className="member-cell col-2 text-start">
                              {gateway.status}
                            </td>
                            <td className={`member-cell col-2 text-start `}>
                              <div
                                className={`${getStatusClass(
                                  gateway.gatewaystatus
                                )} py-1 text-center rounded`}
                              >
                                {gateway.gatewaystatus}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="no-record-wrapper oRecordDiv">
                            <i className="bi bi-search fs-1 mb-3" />
                            <div className="text-font2 ">
                              {intl.formatMessage({ id: "noRecordFound" })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <Skeleton height="600px" className="w-100" />
                      </td>
                    </tr>
                  )}
                </tbody>
                <div className="pagination-container">
                  {memberState &&
                    memberState.memberGatewayLists &&
                    Array.from(
                      { length: memberState.memberGatewayLists.totalpage },
                      (_, i) => i + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`pagination-button ${
                          currentGatewayPage === pageNumber ? "active" : ""
                        }`}
                        onClick={() => handlePageClick1(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                </div>
              </table>
            </div>
          </div>
        ) : selectedNav === MemberReportConst.TELCO ? (
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "requestDate" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "respondDate" })}
                    </th>
                    <th className="member-cell col-2 text-end">
                      {intl.formatMessage({ id: "amount" })}
                    </th>
                    <th className="member-cell col-3 text-start">
                      {intl.formatMessage({ id: "mobileDetails" })}
                    </th>
                    <th className="member-cell col-2 text-start">
                      {intl.formatMessage({ id: "checkId" })}
                    </th>
                    <th className="member-cell col-2 text-start">
                      {intl.formatMessage({ id: "status" })}
                    </th>
                    <th className="member-cell col-2 text-nowrap text-start">
                      {intl.formatMessage({ id: "telcoStatus" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {memberState.memberTelcoLists?.memberTelcoReports ? (
                    memberState.memberTelcoLists.memberTelcoReports.length >
                    0 ? (
                      memberState.memberTelcoLists.memberTelcoReports.map(
                        (telco, index) => (
                          <tr key={index} className="member-row1">
                            <td className="member-cell hide-on-mobile col-1">
                              {index + 1}
                            </td>
                            <td className="member-cell col-2 text-nowrap">
                              {telco.requestdate}
                            </td>
                            <td className="member-cell col-2 text-nowrap">
                              {telco.responsedate}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {telco.amount}
                            </td>
                            <td className="member-cell col-3 text-start text-nowrap">
                              <div className="d-flex align-items-center ">
                                {telco.telcoid}
                                <i
                                  className="bi bi-copy mx-1"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    if (
                                      sessionStorage.getItem("platform") ===
                                      "mobile"
                                    ) {
                                      console.log(
                                        `clipboard: ${telco.telcoid}`
                                      );
                                      CopyToClipboard(
                                        telco.telcoid,
                                        setSwalProps,
                                        intl
                                      );
                                    } else {
                                      CopyToClipboard(
                                        telco.telcoid,
                                        setSwalProps,
                                        intl
                                      );
                                    }
                                  }}
                                />
                              </div>
                              <div>{telco.telconame}</div>
                              <div>{telco.mobilename}</div>
                            </td>
                            <td className="member-cell col-2 text-start ">
                              {telco.checkid}
                            </td>
                            <td className="member-cell col-2 text-start">
                              {telco.status}
                            </td>
                            <td className={`member-cell col-2 text-start `}>
                              <div
                                className={`${getStatusClass(
                                  telco.telcostatus
                                )} py-1 text-center rounded`}
                              >
                                {telco.telcostatus}
                              </div>
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="8">
                          <div className="no-record-wrapper oRecordDiv">
                            <i className="bi bi-search fs-1 mb-3" />
                            <div className="text-font2 ">
                              {intl.formatMessage({ id: "noRecordFound" })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <Skeleton height="600px" className="w-100" />
                      </td>
                    </tr>
                  )}
                </tbody>
                <div className="pagination-container">
                  {memberState &&
                    memberState.memberTelcoLists &&
                    Array.from(
                      { length: memberState.memberTelcoLists.totalpage },
                      (_, i) => i + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`pagination-button ${
                          currentTelcoPage === pageNumber ? "active" : ""
                        }`}
                        onClick={() => handlePageClick2(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                </div>
              </table>
            </div>
          </div>
        ) : selectedNav === MemberReportConst.VBOSS ? (
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2">
                      {intl.formatMessage({ id: "date" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "beforeAmount" })}
                    </th>
                    <th className="member-cell col-2 text-end">
                      {intl.formatMessage({ id: "amount" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "afterAmount" })}
                    </th>
                    <th className="member-cell col-3 text-start">
                      {intl.formatMessage({ id: "action" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {memberState.vBossReport?.memberVBossReports ? (
                    memberState.vBossReport.memberVBossReports.length > 0 ? (
                      memberState.vBossReport.memberVBossReports.map(
                        (member, index) => (
                          <tr key={index} className="member-row1">
                            <td className="member-cell hide-on-mobile col-1">
                              {index + 1}
                            </td>
                            <td className="member-cell col-2">
                              {member.reportdate}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.bfamount}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.amount}
                            </td>
                            <td className="member-cell col-2 text-end">
                              {member.afamount}
                            </td>
                            <td className="member-cell col-3 text-start">
                              {member.message}
                            </td>
                          </tr>
                        )
                      )
                    ) : (
                      <tr>
                        <td colSpan="6">
                          <div className="no-record-wrapper oRecordDiv">
                            <i className="bi bi-search fs-1 mb-3" />
                            <div className="text-font2 ">
                              {intl.formatMessage({ id: "noRecordFound" })}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  ) : (
                    <tr>
                      <td colSpan="6">
                        <Skeleton height="600px" className="w-100" />
                      </td>
                    </tr>
                  )}
                </tbody>
                <div className="pagination-container">
                  {memberState &&
                    memberState.vBossReport &&
                    Array.from(
                      { length: memberState.vBossReport.totalpage },
                      (_, i) => i + 1
                    ).map((pageNumber) => (
                      <button
                        key={pageNumber}
                        className={`pagination-button ${
                          currentVBossPage === pageNumber ? "active" : ""
                        }`}
                        onClick={() => handlePageClick4(pageNumber)}
                      >
                        {pageNumber}
                      </button>
                    ))}
                </div>
              </table>
            </div>
          </div>
        ) : (
          <>
            <div>
              <select
                className="dropdown inputBox1"
                onChange={handleCompanyChange}
              >
                {memberState &&
                  memberState.memberGameLogReport &&
                  memberState.memberGameLogReport.memberGames &&
                  memberState.memberGameLogReport.memberGames.map((company) => (
                    <option
                      key={company.companycode}
                      value={company.companycode}
                    >
                      {company.companyname}
                    </option>
                  ))}
              </select>

              {/* <select
                className="dropdown"
                value={selectedGameType}
                onChange={handleGameTypeChange}
                disabled={isGameTypeDisabled}
              >
                {gameTypes.map((game) => (
                  <option key={game.gametype} value={game.gametype}>
                    {game.gametype}
                  </option>
                ))}
              </select> */}
            </div>
            {memberState &&
            memberState.memberGameLogReport &&
            memberState.memberGameLogReport.reporturl == "" ? (
              <>
                <div className="member-container">
                  <div className="table-responsive">
                    <table className="member-table">
                      <thead className="member-header1">
                        <tr className="member-row1">
                          <th className="member-cell hide-on-mobile col-1">
                            {intl.formatMessage({ id: "no" })}
                          </th>
                          <th className="member-cell col-2">
                            {intl.formatMessage({ id: "betDate" })}
                          </th>
                          <th className="member-cell col-2 text-start">
                            {intl.formatMessage({ id: "companyName" })}
                          </th>
                          <th className="member-cell col-2 text-start">
                            {intl.formatMessage({ id: "gameName" })}
                          </th>
                          <th className="member-cell col-1 text-nowrap text-start">
                            {intl.formatMessage({ id: "gameType" })}
                          </th>
                          <th className="member-cell col-2 text-end">
                            {intl.formatMessage({ id: "totalBet" })}
                          </th>
                          <th className="member-cell col-2 text-end">
                            {intl.formatMessage({ id: "totalWin" })}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="member-body">
                        {memberState.memberGameLogReport
                          ?.memberGameLogReports ? (
                          memberState.memberGameLogReport.memberGameLogReports
                            .length > 0 ? (
                            memberState.memberGameLogReport.memberGameLogReports.map(
                              (report, index) => (
                                <tr key={index} className="member-row1">
                                  <td className="member-cell hide-on-mobile col-1">
                                    {index + 1}
                                  </td>
                                  <td className="member-cell col-2 text-nowrap">
                                    {report.betdate}
                                  </td>
                                  <td className="member-cell col-2 text-nowrap text-start">
                                    {report.companyname}
                                  </td>
                                  <td className="member-cell col-2 text-nowrap text-start">
                                    {report.gamename}
                                  </td>
                                  <td className="member-cell col-1 text-start ">
                                    {report.gametype}
                                  </td>
                                  <td className="member-cell col-2 text-end">
                                    {report.totalbet}
                                  </td>
                                  <td className="member-cell col-2 text-end">
                                    {report.totalwin}
                                  </td>
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td colSpan="7">
                                <div className="no-record-wrapper oRecordDiv">
                                  <i className="bi bi-search fs-1 mb-3" />
                                  <div className="text-font2 ">
                                    {intl.formatMessage({
                                      id: "noRecordFound",
                                    })}
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )
                        ) : (
                          <tr>
                            <td colSpan="6">
                              <Skeleton height="600px" className="w-100" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <>
                <iframe
                  style={{
                    width: "100%",
                    minHeight: "400px",
                  }}
                  src={memberState.memberGameLogReport.reporturl}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
