import { useIntl } from "react-intl";
import { useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal1";
import { EditAgentForm } from "./EditAgentForm";

export const EditAgentAccDialog = ({
  setSwalProps,
  agentId,
  accttype,
  acctdaycount,
  acctisconnect,
  isVisible,
  onClose,
}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  const handleClick = () => {
    const queryParams = {
      action: "connectaccountweb",
      selagentid: agentId,
      accttype: accttype,
      acctdaycount: acctdaycount,
      acctisconnect: `${acctisconnect}`,
    };
    dispatch(actions.agentAccConnect(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "agentAccWebSuccess" }),
          onConfirm: () => {
            const queryParams = {
              action: "agentdetail",
              selagentid: agentId,
            };
            dispatch(actions.callAgentDetails(queryParams));
            dismissBtnRef.current.click();
            onClose();
          },
          onClose: () => {
            onClose();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };

  return (
    <Modal
      modalId="editAgentAccModal"
      title={intl.formatMessage({ id: "editAgentAcc" })}
      dismissBtnRef={dismissBtnRef}
      isVisible={isVisible}
      onClose={onClose}
    >
      <div className="p-3 custom-border-bottom">
        {intl.formatMessage({ id: "confirmEdit" })}
      </div>
      <div className="p-3 d-flex justify-content-end align-items-center">
        <button
          className="btn btn-success btn-sm mx-1"
          type="submit"
          onClick={handleClick}
        >
          {intl.formatMessage({ id: "confirm" })}
        </button>
        <button
          className="btn btn-danger btn-sm"
          type="button"
          onClick={handleCancel}
        >
          {intl.formatMessage({ id: "cancel" })}
        </button>
      </div>
    </Modal>
  );
};
