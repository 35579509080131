import React, { useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import "./DateTimeRangeComp.css";

const SingleInputDateTimeRangeField = ({
  startDateTime,
  endDateTime,
  onChange,
}) => {
  const [dates, setDates] = useState({
    start: startDateTime,
    end: endDateTime,
  });

  const now = moment(); // Get the current time

  const formatDate = (date) => moment(date).format("YYYY-MM-DD HH:mm");

  const handleDateChange = (date, type) => {
    const selectedDate = moment.isMoment(date) ? date : moment(date); // Ensure date is a moment object
    const formattedDate = formatDate(selectedDate);

    if (!selectedDate.isValid()) {
      return; // Do nothing if date is invalid
    }

    if (type === "start") {
      if (selectedDate.isAfter(now)) {
        // Set the date to now if it is in the future
        const updatedDates = { start: formatDate(now), end: formatDate(now) };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      } else if (selectedDate.isAfter(dates.end)) {
        // Ensure start date is before end date
        const updatedDates = { start: formattedDate, end: formattedDate };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      } else {
        const updatedDates = { ...dates, start: formattedDate };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      }
    } else if (type === "end") {
      if (selectedDate.isAfter(now)) {
        // Set the date to now if it is in the future
        const updatedDates = { ...dates, end: formatDate(now) };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      } else if (selectedDate.isBefore(dates.start)) {
        // Ensure end date is after start date
        const updatedDates = { ...dates, end: formatDate(dates.start) };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      } else {
        const updatedDates = { ...dates, end: formattedDate };
        setDates(updatedDates);
        onChange(updatedDates.start, updatedDates.end);
      }
    }
  };

  // Set the maximum date to now
  const maxDate = now.endOf("day");

  const isEndDateValid = (currentDate) => {
    const startDate = moment(dates.start);
    return (
      currentDate.isBefore(maxDate) &&
      (currentDate.isSame(startDate, "day") ||
        currentDate.isSame(startDate.clone().add(1, "day"), "day"))
    );
  };

  return (
    <div className="dateTimeDiv">
      <Datetime
        inputProps={{ className: "inputBox", placeholder: "YYYY-MM-DD HH:mm" }}
        value={dates.start}
        onChange={(date) => handleDateChange(date, "start")}
        dateFormat="YYYY-MM-DD"
        timeFormat="HH:mm"
        isValidDate={(currentDate) => currentDate.isBefore(maxDate)}
      />
      <div className="dateTimeSpan">
        <span>-</span>
      </div>
      <Datetime
        value={dates.end}
        inputProps={{ className: "inputBox", placeholder: "YYYY-MM-DD HH:mm" }}
        onChange={(date) => handleDateChange(date, "end")}
        dateFormat="YYYY-MM-DD"
        timeFormat="HH:mm"
        isValidDate={isEndDateValid}
      />
    </div>
  );
};

export default SingleInputDateTimeRangeField;
