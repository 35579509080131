import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PlatfromSettingAction";
import { Switch } from "antd";
import Skeleton from "react-loading-skeleton";
import "./PlatformSetting.css";
export const GameActiveList = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedGameType, setSelectedGameType] = useState("");
  const [platformSettingPermission, setPlatformSettingPermission] =
    useState("");
  const [showList, setShowList] = useState(false);
  const platformSettingState = useSelector(
    ({ platformSetting }) => platformSetting,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "gamesuspendalllist",
      companycode: "",
      gametype: "",
    };
    dispatch(actions.callGameSuspendList(queryParams));
    setSelectedCompany("allbet");
  }, []);
  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );

    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "platformsetting":
            setPlatformSettingPermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);
  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
  };
  const handleGameTypeChange = (event) => {
    setSelectedGameType(event.target.value);
  };
  const getGameTypes = () => {
    if (
      platformSettingState &&
      platformSettingState.gameSuspendList &&
      platformSettingState.gameSuspendList.companyListsInSuspends
    ) {
      const company =
        platformSettingState.gameSuspendList.companyListsInSuspends.find(
          (company) => company.companycode === selectedCompany
        );
      return company ? company.gameSuspendTypeLists : [];
    }
    return [];
  };

  const gameTypes = getGameTypes();
  const isGameTypeDisabled = gameTypes.length <= 1;

  useEffect(() => {
    if (gameTypes.length > 0) {
      setSelectedGameType(gameTypes[0].gametype);
    }
  }, [selectedCompany]);

  const handleSearchButton = () => {
    setShowList(true);
    const queryParams = {
      action: "gamesuspendalllist",
      companycode: selectedCompany,
      gametype: selectedGameType,
    };
    dispatch(actions.callGameSuspendList(queryParams));
  };

  const handleSwitchChange = (checked, gamecode) => {
    const updateQueryParams = {
      action: "suspendactivegame",
      companycode: selectedCompany,
      gametype: selectedGameType,
      gamecode: gamecode,
    };
    dispatch(actions.updateSuspendActiveStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "gamesuspendalllist",
          companycode: selectedCompany,
          gametype: selectedGameType,
        };
        dispatch(actions.callGameSuspendList(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Suspend Active status:", error);
      });
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <div>
        <h2>{intl.formatMessage({ id: "gameActiveList" })}</h2>
      </div>
      {/* <h4>{intl.formatMessage({ id: "gameList" })}</h4> */}
      <div className="platform-container">
        <div className="platform-header">
          {platformSettingState &&
            platformSettingState.gameSuspendList &&
            platformSettingState.gameSuspendList.companyListsInSuspends && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <select className="dropdown" onChange={handleCompanyChange}>
                    {platformSettingState.gameSuspendList.companyListsInSuspends.map(
                      (company) => (
                        <option
                          key={company.companycode}
                          value={company.companycode}
                        >
                          {company.companyname}
                        </option>
                      )
                    )}
                  </select>

                  <select
                    className="dropdown"
                    value={selectedGameType}
                    onChange={handleGameTypeChange}
                    disabled={isGameTypeDisabled}
                  >
                    {gameTypes.map((game) => (
                      <option key={game.gametype} value={game.gametype}>
                        {game.gametype}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    onClick={handleSearchButton}
                  >
                    {intl.formatMessage({ id: "search" })}
                  </button>
                </div>
              </div>
            )}
        </div>
        <div className="platform-body">
          {showList && (
            <div>
              {platformSettingState.gameSuspendList &&
              platformSettingState.gameSuspendList.gameSuspendLists ? (
                platformSettingState.gameSuspendList.gameSuspendLists.length >
                  0 &&
                platformSettingState.gameSuspendList.gameSuspendLists.map(
                  (game, index) => (
                    <div key={index} className="platform-row">
                      <div className="platform-cell" style={{ flex: "1" }}>
                        {index + 1}
                      </div>
                      <div className="platform-cell" style={{ flex: "4" }}>
                        {game.gamename}
                      </div>
                      {platformSettingPermission === "true" ? (
                        <div className="platform-cell" style={{ flex: "2" }}>
                          <Switch
                            checked={game.issuspended === "false"}
                            onChange={(checked) =>
                              handleSwitchChange(checked, game.gamecode)
                            }
                          />
                        </div>
                      ) : (
                        <div className="platform-cell" style={{ flex: "2" }}>
                          {game.issuspended === "false"
                            ? intl.formatMessage({ id: "active" })
                            : intl.formatMessage({ id: "inactive" })}
                        </div>
                      )}
                    </div>
                  )
                )
              ) : (
                <Skeleton height="600px" className="w-100" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
