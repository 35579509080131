import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const accountCreditState = {
  masterCreditList: null,
  withdrawRequest: null,
  accountCreditList: null,
  creditWithdrawCancel: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const accountCreditSlice = createSlice({
  name: "accountCredit",
  initialState: accountCreditState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    masterCreditListFetch: (state, action) => {
      state.actionsLoading = false;
      state.masterCreditList = action.payload.data ?? {};
    },
    withdrawRequestFetch: (state, action) => {
      state.actionsLoading = false;
      state.withdrawRequest = action.payload.data ?? {};
    },
    accountCreditListFetch: (state, action) => {
      state.actionsLoading = false;
      state.accountCreditList = action.payload.data ?? {};
    },
    creditWithdrawCancelFetch: (state, action) => {
      state.actionsLoading = false;
      state.creditWithdrawCancel = action.payload.data ?? {};
    },
  },
});
