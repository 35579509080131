import { useIntl } from "react-intl";
import React, { createContext, useEffect, useState } from "react";
import SideBar from "../slidebar/components/SideBar";
import "./Masterpage.css";
import Header from "../main/components/Header";
import { Outlet } from "react-router-dom";
import PageTitle from "../main/components/PageTitle";
import "../main/components/main.css";
const MasterLayoutContext = createContext();

export const MasterLayout = () => {
  const intl = useIntl();
  const value = {};

  return (
    <MasterLayoutContext.Provider value={value}>
      <div style={{ height: "8vh", width: "100%" }}>
        <Header />
      </div>
      <div style={{ width: "100%" }}>
        <SideBar />
        <div id="main" className="main">
          <Outlet />
        </div>
      </div>
    </MasterLayoutContext.Provider>
  );
};
