import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/PlatfromSettingAction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/components/AuthInit";

export const AppDownload = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [agentAppleAppUrl, setAgentAppleAppUrl] = useState(null);
  const [agentAndroidAppUrl, setAgentAndroidAppUrl] = useState(null);
  const platformSettingState = useSelector(
    ({ platformSetting }) => platformSetting,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "appwebinfo",
    };
    dispatch(actions.callDownloadList(queryParams)).then((resp) => {
      resp = resp.data;
      const iosUrl = JSON.stringify(resp.agentappurl_ios);
      const androidUrl = JSON.stringify(resp.agentappurl_android);
      setAgentAndroidAppUrl(androidUrl);
      setAgentAppleAppUrl(iosUrl);
    });
  }, []);

  const customCardClass = "rounded align-items-center px-2 py-2";

  return (
    <div style={{ width: "100%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />

      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>{intl.formatMessage({ id: "appDownload" })}</h2>
      </div>

      {platformSettingState && platformSettingState.downloadList && (
        <div className="d-flex justify-content-evenly">
          <div
            className={`${customCardClass} cursor-pointer text-center downloadList-background`}
            onClick={() => {
              const cleanUrl = agentAndroidAppUrl.replace(/"/g, "");
              window.location.href = cleanUrl;
            }}
          >
            <i
              className="bi bi-android2"
              style={{ fontSize: "40px", color: "#A4C639" }}
            ></i>
            <div>{intl.formatMessage({ id: "androidDownload" })}</div>
          </div>
          <div
            className={`${customCardClass} cursor-pointer text-center downloadList-background`}
            onClick={() => {
              const cleanUrl = agentAppleAppUrl.replace(/"/g, "");
              window.location.href = cleanUrl;
            }}
          >
            <i
              className="bi bi-apple"
              style={{ fontSize: "40px", color: "#C0C0C0" }}
            ></i>
            <div>{intl.formatMessage({ id: "appleDownload" })}</div>
          </div>
        </div>
      )}
    </div>
  );
};
