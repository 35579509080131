export const TopupAndWithdrawConst = {
  TOPUP: "topup",
  WITHDRAW: "withdraw",
};
export const BalanceAndQuotaConst = {
  BALANCE: "balance",
  // ACCTQUOTA: "acctQuota",
};
export const ApproveAndWithdrawConst = {
  APPROVE: "approve",
  REJECT: "reject",
};
