import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const reportState = {
  agentReportLists: null,
  agentDownlineReportLists: null,
  agentDownlineDetailsLists: null,
  transactionReportLists: null,
  summaryReportLists: null,
  agentDownlineMemberReportLists: null,
  agentDownlineMemberDetailsLists: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const reportSlice = createSlice({
  name: "report",
  initialState: reportState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    agentReportFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentReportLists = action.payload.data ?? {};
    },
    agentDownlineReportFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentDownlineReportLists = action.payload.data ?? {};
    },
    agentDownlineDetailsFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentDownlineDetailsLists = action.payload.data ?? {};
    },
    transactionReportFetch: (state, action) => {
      state.actionsLoading = false;
      state.transactionReportLists = action.payload.data ?? {};
    },
    summaryReportFetch: (state, action) => {
      state.actionsLoading = false;
      state.summaryReportLists = action.payload.data ?? {};
    },
    agentDownlineMemberReportFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentDownlineMemberReportLists = action.payload.data ?? {};
    },
    agentDownlineMemberDetailsFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentDownlineMemberDetailsLists = action.payload.data ?? {};
    },
  },
});
