import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import SweetAlert2 from "react-sweetalert2";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ProjectCreditAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { useAuth } from "../../auth/components/AuthInit";
import { ProjectCreditForm } from "./ProjectCreditForm";
import { ProjectCreditConst } from "../../../constants/ProjectCreditConst";
import { ProjectCreditHistory } from "./ProjectCreditHistory";
import { useLocation } from "react-router-dom";

export const ProjectCreditDialog = ({}) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [balance, setBalance] = useState("");
  const [selectedNav, setSelectedNav] = useState(ProjectCreditConst.WITHDRAW);
  const accountCreditState = useSelector(
    ({ accountCredit }) => accountCredit,
    shallowEqual
  );
  const location = useLocation();

  useEffect(() => {
    const balance = location.state?.balance;
    setBalance(balance);
  }, []);

  const saveValues = (queryParams) => {
    dispatch(actions.callAccountCreditList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "submitWithdrawSuccess" }),
          onConfirm: () => {
            formikRef.current.resetForm();
            // const queryParams = {
            //   action: "mastercontactlist",
            // };
            // dispatch(actions.callContactList(queryParams));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "projectCreditWithdraw" })}</h2>
      </div>

      <div className="d-flex">
        {Object.entries(ProjectCreditConst).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "#0080FF" : "",
              borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3"
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>
      {selectedNav === ProjectCreditConst.WITHDRAW ? (
        <div style={{ maxWidth: "600px", margin: "0 auto" }}>
          <div className="p-3 custom-border-bottom">
            <ProjectCreditForm
              formikRef={formikRef}
              saveValues={saveValues}
              balance={balance}
            />
          </div>
          <div className="p-3 d-flex justify-content-end align-items-center">
            <button
              type="button"
              className="btn btn-primary px-3"
              onClick={() => formikRef.current.submitForm()}
            >
              {intl.formatMessage({ id: "withdraw" })}
            </button>
          </div>
        </div>
      ) : (
        <ProjectCreditHistory />
      )}
    </div>
  );
};
