import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { Modal } from "../../common/Modal";
import SweetAlert2 from "react-sweetalert2";
import { CopyToClipboard } from "../../../utils/CommonHelper";

export const ShareDialog = ({ selAgentId, selMemberId, fromSide }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "shareagentaccount",
      selagentid: selAgentId,
    };
    dispatch(actions.shareAgentDetails(queryParams));
  }, [selAgentId]);

  useEffect(() => {
    const queryParams = {
      action: "sharememberaccount",
      selmemberid: selMemberId,
    };
    dispatch(actions.shareMemberDetails(queryParams));
  }, [selMemberId]);
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <Modal
        modalId="ShareModal"
        title={intl.formatMessage({ id: "shareDetails" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          {fromSide === "agent"
            ? agentState &&
              agentState.shareAgentDetails && (
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                      <div>{intl.formatMessage({ id: "webUrl" })}</div>
                      <div>{intl.formatMessage({ id: "username" })}</div>
                      <div>{intl.formatMessage({ id: "password" })}</div>
                    </div>
                    <div className="mx-2">
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                    </div>
                    <div>
                      <div>{agentState.shareAgentDetails.weburl}</div>
                      <div>{agentState.shareAgentDetails.username}</div>
                      <div>{agentState.shareAgentDetails.password}</div>
                    </div>
                  </div>
                  <div className="align-content-center mx-5">
                    <i
                      className="bi bi-copy"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (sessionStorage.getItem("platform") === "mobile") {
                          console.log(
                            `clipboard: ${intl.formatMessage({
                              id: "webUrl",
                            })}:${
                              agentState.shareAgentDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareAgentDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareAgentDetails.password
                            }`
                          );
                          CopyToClipboard(
                            `${intl.formatMessage({ id: "webUrl" })}:${
                              agentState.shareAgentDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareAgentDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareAgentDetails.password
                            }`,
                            setSwalProps,
                            intl
                          );
                        } else {
                          CopyToClipboard(
                            `${intl.formatMessage({ id: "webUrl" })}:${
                              agentState.shareAgentDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareAgentDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareAgentDetails.password
                            }`,
                            setSwalProps,
                            intl
                          );
                        }
                      }}
                    ></i>
                  </div>
                </div>
              )
            : agentState &&
              agentState.shareMemberDetails && (
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                      <div>{intl.formatMessage({ id: "webUrl" })}</div>
                      <div>{intl.formatMessage({ id: "username" })}</div>
                      <div>{intl.formatMessage({ id: "password" })}</div>
                    </div>
                    <div className="mx-2">
                      <div>:</div>
                      <div>:</div>
                      <div>:</div>
                    </div>
                    <div>
                      <div>{agentState.shareMemberDetails.weburl}</div>
                      <div>{agentState.shareMemberDetails.username}</div>
                      <div>{agentState.shareMemberDetails.password}</div>
                    </div>
                  </div>
                  <div className="align-content-center mx-5">
                    <i
                      className="bi bi-copy"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (sessionStorage.getItem("platform") === "mobile") {
                          console.log(
                            `clipboard: ${intl.formatMessage({
                              id: "webUrl",
                            })}:${
                              agentState.shareMemberDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareMemberDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareMemberDetails.password
                            }`
                          );
                          CopyToClipboard(
                            `${intl.formatMessage({ id: "webUrl" })}:${
                              agentState.shareMemberDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareMemberDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareMemberDetails.password
                            }\n`,
                            setSwalProps,
                            intl
                          );
                        } else {
                          CopyToClipboard(
                            `${intl.formatMessage({ id: "webUrl" })}:${
                              agentState.shareMemberDetails.weburl
                            }\n${intl.formatMessage({ id: "username" })}:${
                              agentState.shareMemberDetails.username
                            }\n${intl.formatMessage({ id: "password" })}:${
                              agentState.shareMemberDetails.password
                            }\n`,
                            setSwalProps,
                            intl
                          );
                        }
                      }}
                    ></i>
                  </div>
                </div>
              )}
        </div>
      </Modal>
    </div>
  );
};
