import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/PlatfromSettingAction";
import { Switch } from "antd";

export const CompanyGameList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [platformSettingPermission, setPlatformSettingPermission] =
    useState("");
  const dispatch = useDispatch();
  const platformSettingState = useSelector(
    ({ platformSetting }) => platformSetting,
    shallowEqual
  );
  useEffect(() => {
    const queryParams = {
      action: "companygamelist",
    };
    dispatch(actions.callCompanyGameList(queryParams));
  }, []);
  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );

    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "platformsetting":
            setPlatformSettingPermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);
  const handleSwitchChange = (checked, code) => {
    const updateQueryParams = {
      action: "updatecompanygamevisible",
      companycode: code,
    };
    dispatch(actions.updateCompanyGameList(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "companygamelist",
        };
        dispatch(actions.callCompanyGameList(queryParams));
      })
      .catch((error) => {
        console.error("Error updating company game list:", error);
      });
  };
  return (
    <div style={{ width: "95%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "companyGameList" })}</h2>
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "companyName" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "visible" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {platformSettingState.companyGameList &&
                platformSettingState.companyGameList.companyGameLists &&
                platformSettingState.companyGameList.companyGameLists.length >
                  0 ? (
                  platformSettingState.companyGameList.companyGameLists.map(
                    (game, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-nowrap text-start">
                          {game.companyname}
                        </td>
                        {platformSettingPermission === "true" ? (
                          <td className="member-cell col-2 text-nowrap">
                            <Switch
                              checked={game.isvisible === "true"}
                              onChange={(checked) =>
                                handleSwitchChange(checked, game.companycode)
                              }
                            />
                          </td>
                        ) : (
                          <td className="member-cell col-2 text-nowrap">
                            {game.isvisible === "true"
                              ? intl.formatMessage({ id: "active" })
                              : intl.formatMessage({ id: "inactive" })}
                          </td>
                        )}
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="3">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
