import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import { useNavigate } from "react-router-dom";
import { ImageDialog } from "./Image";
import DateRangeComp from "../../common/DateRangeComp";
import { formatInTimeZone } from "date-fns-tz";
import { ApproveAndWithdrawConst } from "../../../constants/TopUpAndWithdrawConst";

export const TopupRespondList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [currentImageUrl, setCurrentImageUrl] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [selectedNav, setSelectedNav] = useState("");
  const timeZone = "Asia/Kuala_Lumpur";
  const dispatch = useDispatch();
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "manualtopuprespondedlist",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callTopupRespondList(queryParams));
  }, [fromDate, toDate, dispatch]);

  const handleImageClick = (url) => {
    setCurrentImageUrl(url);
  };

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    const queryParams = {
      action: "manualtopuprespondedlist",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callTopupRespondList(queryParams));
  };

  const filteredReports =
    transactionState.callTopupRespondLists?.manualTopupRespondedLists?.filter(
      (report) =>
        !selectedNav ||
        report.responsesstatus.toLowerCase() === selectedNav.toLowerCase()
    ) || [];

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <ImageDialog currentImageUrl={currentImageUrl} />

      <div className="d-flex justify-content-between">
        <div>
          <h2>{intl.formatMessage({ id: "topupHistory" })}</h2>
        </div>
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button className="btn btn-primary" onClick={handleSearchClick}>
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-end">
        <select
          className="dropdown respond-Select"
          value={selectedNav}
          onChange={(e) => setSelectedNav(e.target.value)}
        >
          <option value="">{intl.formatMessage({ id: "all" })}</option>
          {Object.entries(ApproveAndWithdrawConst).map(([key, value]) => (
            <option value={value.toLowerCase()} key={key}>
              {intl.formatMessage({ id: value })}
            </option>
          ))}
        </select>
      </div>

      <div className="member-div d-flex" style={{ margin: "10px auto" }}>
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap">
                    {intl.formatMessage({ id: "requestDate" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "bankDetails" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "receipt" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap">
                    {intl.formatMessage({ id: "respondDate" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-start">
                    {intl.formatMessage({ id: "status" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-start">
                    {intl.formatMessage({ id: "handleBy" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {filteredReports.length > 0 ? (
                  filteredReports.map((report, index) => (
                    <tr key={index} className="member-row1">
                      <td className="member-cell hide-on-mobile col-1">
                        {index + 1}
                      </td>
                      <td className="member-cell col-2 text-nowrap">
                        {report.requestdate}
                      </td>
                      <td className="member-cell col-2 text-start">
                        {report.username}
                      </td>

                      <td className="member-cell col-2 text-start">
                        <div>{report.bankname}</div>
                        <div>{report.accountnum}</div>
                        <div>{report.accountname}</div>
                      </td>
                      <td className="member-cell col-2 text-end">
                        {report.amount}
                      </td>
                      <td
                        className="member-cell col-1 text-nowrap"
                        style={{
                          maxWidth: "100px",
                          color: "#0000FF",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#imageModal"
                        onClick={() => handleImageClick(report.imageurl)}
                      >
                        <i
                          className="bi bi-image"
                          style={{ fontSize: "3.5vh" }}
                        />
                      </td>
                      <td className="member-cell col-2 text-nowrap">
                        {report.responsesdate}
                      </td>
                      <td className="member-cell col-2 text-start ">
                        <span
                          style={{
                            color:
                              report.responsesstatus === "APPROVE"
                                ? "green"
                                : "red",
                          }}
                        >
                          {report.responsesstatus}
                        </span>
                      </td>
                      <td className="member-cell col-2 text-start">
                        {report.handlebyusername}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
