import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import Dashboard from "../../modules/dashboard/components/Dashboard";

const DashboardContext = createContext();

const DashbordWrapper = () => {
  const value = {};

  return (
    <DashboardContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Dashboard />} />
      </Routes>
    </DashboardContext.Provider>
  );
};

export { DashbordWrapper };
