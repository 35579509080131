import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/TransactionAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";

export const WithdrawUpdateDialog = ({ setSwalProps, itemId }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [currentStatus, setCurrentStatus] = useState("");

  const handleClick = () => {
    const queryParams = {
      action: "withdrawupdate",
      selitemid: itemId,
      onprogressreject: "onprogress",
      message: "",
    };
    dispatch(actions.updateWithdrawList(queryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "updateSuccess" }),
          onConfirm: () => {
            dismissBtnRef.current.click();
            const queryParams = {
              action: "withdrawrequestlist",
            };

            dispatch(actions.callWithdrawList(queryParams));
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="WithdrawUpdateModal"
        title={intl.formatMessage({ id: "withdrawUpdateStatus" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          {intl.formatMessage({ id: "confirmOnProgress" })}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          <button className="btn btn-success btn-sm mx-1" onClick={handleClick}>
            {intl.formatMessage({ id: "confirm" })}
          </button>
          <button className="btn btn-danger btn-sm" onClick={handleCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
