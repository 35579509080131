import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./TransactionCRUD";
import { transactionSlice } from "./TransactionSlice";

const { actions } = transactionSlice;

export const callATMTransactionList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkTransactionList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.atmTransactionListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callTopupRespondList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkRespondList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.callTopupRespondListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: callTopupRespondListsFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callPaymentGatewayList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkTransactionList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(
            actions.paymentGatewayTransactionListsFetch({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callTelcoList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkTransactionList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.telcoTransactionListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callWithdrawList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkWithdrawList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callWithdrawResondList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkRespondList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawRespondListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawRespondListsFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const updateWithdrawList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkWithdrawList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.updateWithdrawListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const approveWithdrawList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .withdrawApproveList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.approveWithdrawListsFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const updateManualTopup = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkWithdrawList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.updateManualTopupFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: checkTransactionList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
export const callPaymentOnHoldList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .checkOnHoldList(queryParams)
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.paymentOnHoldListFetch({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: paymentOnHoldListFetch");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
