import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const agentState = {
  agentLists: null,
  addAgentLists: null,
  callAgentDetails: null,
  updateAgentDetails: null,
  editAgentDetails: null,
  agentTopupAndWithdraw: null,
  agentGameLists: null,
  agentFreeChargeLists: null,
  updateAgentFreeChargeLists: null,
  agent4DLists: null,
  setupAgent4DLists: null,
  agent4DConnectLists: null,
  updateAgentCreateDownline: null,
  quotaTopupWithdraw: null,
  agentAccConnect: null,
  agent4DPrizeList: null,
  updateAgent4DPrizeList: null,
  shareAgentDetails: null,
  shareMemberDetails: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const agentSlice = createSlice({
  name: "agent",
  initialState: agentState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    agentListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentLists = action.payload.data ?? {};
    },
    addAgentListsFetch: (state, action) => {
      state.actionsLoading = false;
      state.addAgentLists = action.payload.data ?? {};
    },
    callAgentDetailsFetch: (state, action) => {
      state.actionsLoading = false;
      state.callAgentDetails = action.payload.data ?? {};
    },
    updateAgentFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateAgentDetails = action.payload.data ?? {};
    },
    editAgentFetch: (state, action) => {
      state.actionsLoading = false;
      state.editAgentDetails = action.payload.data ?? {};
    },
    agentTopupAndWithdrawFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentTopupAndWithdraw = action.payload.data ?? {};
    },
    agentGameListFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentGameLists = action.payload.data ?? {};
    },
    agentFeeChargeFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentFreeChargeLists = action.payload.data ?? {};
    },
    updateAgentFeeChargeFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateAgentFreeChargeLists = action.payload.data ?? {};
    },
    agent4DFetch: (state, action) => {
      state.actionsLoading = false;
      state.agent4DLists = action.payload.data ?? {};
    },
    setupAgent4DFetch: (state, action) => {
      state.actionsLoading = false;
      state.setupAgent4DLists = action.payload.data ?? {};
    },
    agent4DConnectFetch: (state, action) => {
      state.actionsLoading = false;
      state.agent4DConnectLists = action.payload.data ?? {};
    },
    updateAgentCreateDownlineFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateAgentCreateDownline = action.payload.data ?? {};
    },
    quotaTopupWithdrawFetch: (state, action) => {
      state.actionsLoading = false;
      state.quotaTopupWithdraw = action.payload.data ?? {};
    },
    agentAccConnectFetch: (state, action) => {
      state.actionsLoading = false;
      state.agentAccConnect = action.payload.data ?? {};
    },
    agent4DPrizeListFetch: (state, action) => {
      state.actionsLoading = false;
      state.agent4DPrizeList = action.payload.data ?? {};
    },
    agent4DPrizeListUpdateFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateAgent4DPrizeList = action.payload.data ?? {};
    },
    shareAgentDetailsFetch: (state, action) => {
      state.actionsLoading = false;
      state.shareAgentDetails = action.payload.data ?? {};
    },
    shareMemberDetailsFetch: (state, action) => {
      state.actionsLoading = false;
      state.shareMemberDetails = action.payload.data ?? {};
    },
  },
});
