import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import { useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { CopyToClipboard } from "../../../utils/CommonHelper";

export const PaymentOnHoldList = () => {
  const intl = useIntl();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(subDays(new Date(), 30));
  const [toDate, setToDate] = useState(new Date());
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "membertopupholdlist",
    };
    dispatch(actions.callPaymentGatewayList(queryParams));
  }, []);

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "paymentOnHoldList" })}</h2>
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "requestDate" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-2 ">
                    {intl.formatMessage({ id: "action" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {transactionState.paymentOnHoldList &&
                transactionState.paymentOnHoldList.memberTopupHoldLists &&
                transactionState.paymentOnHoldList.memberTopupHoldLists.length >
                  0 ? (
                  transactionState.paymentOnHoldList.memberTopupHoldLists.map(
                    (list, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-nowrap text-start">
                          {list.username}
                        </td>
                        <td className="member-cell col-2 text-nowrap">
                          {list.requestdate}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {list.amount}
                        </td>
                        <td className="member-cell col-2">
                          <button className="btn btn-warning">
                            <i className="bi bi-pen" />
                          </button>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
