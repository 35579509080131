import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { AgentList } from "../../modules/agentlist/components/AgentList";
import { AgentDetails } from "../../modules/agentlist/components/AgentDetails";
import { LotterySetupPrize } from "../../modules/agentlist/components/LotterySetupPrize";

const AgentContext = createContext();

const AgentWrapper = () => {
  const value = {};

  return (
    <AgentContext.Provider value={value}>
      <Routes>
        <Route path="" element={<AgentList />} />
        <Route path="/agentList/:agentId" element={<AgentDetails />} />
        <Route path="/lotterySetup" element={<LotterySetupPrize />} />
      </Routes>
    </AgentContext.Provider>
  );
};

export { AgentWrapper };
