import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/TransactionAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";
import SweetAlert2 from "react-sweetalert2";

export const DeclineDialog = ({ itemId, fromPage }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [reason, setReason] = useState("");
  const [swalProps, setSwalProps] = useState({});

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleClick = () => {
    if (reason != "") {
      if (fromPage == "topup") {
        const queryParams = {
          action: "manualtopupupdate",
          selitemid: itemId,
          approvereject: "reject",
          message: reason,
        };
        dispatch(actions.updateManualTopup(queryParams)).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({ id: "updateSuccess" }),
              onResolve: () => {
                const queryParams = {
                  action: "manualtopuprequestlist",
                };

                dispatch(actions.callATMTransactionList(queryParams));
                setSwalProps({ show: false });
                dismissBtnRef.current.click();
                setReason("");
              },
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.message,
              onResolve: () => {
                setReason("");
              },
            });
          }
        });
      } else if (fromPage == "withdraw") {
        const queryParams = {
          action: "withdrawupdate",
          selitemid: itemId,
          onprogressreject: "reject",
          message: reason,
        };
        dispatch(actions.updateWithdrawList(queryParams)).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({ id: "updateSuccess" }),
              onResolve: () => {
                const queryParams = {
                  action: "withdrawrequestlist",
                };

                dispatch(actions.callWithdrawList(queryParams));
                dismissBtnRef.current.click();
                setReason("");
              },
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.message,
              onResolve: () => {
                setReason("");
              },
            });
          }
        });
      }
    } else {
      setSwalProps({
        show: true,
        icon: "error",
        title: intl.formatMessage({ id: "error" }),
        text: intl.formatMessage({ id: "inputReason" }),
      });
    }
  };

  const handleCancel = () => {
    setReason("");
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <Modal
        modalId="DeclineModal"
        title={intl.formatMessage({ id: "declineTransaction" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="mx-2">
          <div> {intl.formatMessage({ id: "reason" })}</div>
          <div>
            <input
              type="text"
              value={reason}
              onChange={handleReasonChange}
              className="form-control"
              placeholder={intl.formatMessage({ id: "enterReason" })}
            />
          </div>
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          <button className="btn btn-success btn-sm mx-1" onClick={handleClick}>
            {intl.formatMessage({ id: "submit" })}
          </button>
          <button className="btn btn-danger btn-sm" onClick={handleCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
