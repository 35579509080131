import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/AgentAction";
import { useLocation, useNavigate } from "react-router-dom";
import {
  LotteryTypeConst,
  LotteryBetTypeConst,
} from "../../../constants/NavBarConst";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const LotterySetupPrize = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [initialAgentId, setInitialAgentId] = useState(null);
  const [currentAgentId, setCurrentAgentId] = useState(null);
  const [selectedNav, setSelectedNav] = useState(
    LotteryTypeConst.BASIC_LOTTERY
  );
  const [isEditing, setIsEditing] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [betType, setBetType] = useState("");
  const [prizeNumber, setPrizeNumber] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const initialAgentId = location.state?.selAgentId;
    const currentAgentId = location.state?.currentAgentId;
    setInitialAgentId(initialAgentId);
    setCurrentAgentId(currentAgentId);

    const queryParams = {
      action: "agent4dprize",
      selagentid: initialAgentId,
    };
    dispatch(actions.callAgent4DPrize(queryParams));
  }, []);

  const getLotteryPrize = (lotteryBetType) => {
    const mKTPrize = agentState?.agent4DPrizeList?.mKTPrizeLists;
    const hLPrize = agentState?.agent4DPrizeList?.hLPrizeLists;

    const betPrizeList =
      selectedNav === LotteryTypeConst.BASIC_LOTTERY ? mKTPrize : hLPrize;

    if (!betPrizeList) return {}; // Return an empty object or handle the case where betPrizeList is undefined

    const betPrize = betPrizeList.find(
      (betPrize) => betPrize.bettypedscp === lotteryBetType
    );

    return betPrize ? betPrize.betType : {};
  };

  const handleFocus = (editing, betType, prizeNumber) => {
    setIsEditing(editing);
    setBetType(betType);
    setPrizeNumber(prizeNumber);
  };
  const handleBlur = () => {
    const queryParams = {
      action: "agent4dprizeupdate",
      selagentid: initialAgentId,
      companytype: selectedNav,
      bettype: betType,
      prizenumber: prizeNumber,
      prizeamount: inputValue,
    };
    dispatch(actions.updateAgent4DPrize(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        const queryParams = {
          action: "agent4dprize",
          selagentid: initialAgentId,
        };
        dispatch(actions.callAgent4DPrize(queryParams));
      }
      setIsEditing("");
      setInputValue("");
      setBetType("");
      setPrizeNumber("");
    });
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex align-items-center">
        <h2>
          <i
            className="bi bi-chevron-left"
            onClick={() => {
              // if (pathFromSearch == "yes") {
              //   navigate("/searchByUsername", {
              //     state: {
              //       searchUsername: searchUsername,
              //     },
              //   });
              // } else {
              navigate(`/accountList`, {
                state: {
                  returnAgentId: currentAgentId,
                },
              });
              // }
            }}
          ></i>
        </h2>
        <h2>{intl.formatMessage({ id: "lotterySetupPrize" })}</h2>
      </div>
      <div className="d-flex">
        {Object.entries(LotteryTypeConst).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "#0080FF" : "",
              borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3"
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <table className="member-table">
          <thead className="member-header1">
            <tr style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}>
              <th
                className="member-cell col-2 text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {intl.formatMessage({ id: "prize" })}
              </th>
              <th
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {intl.formatMessage({ id: "big" })}
              </th>
              <th
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {intl.formatMessage({ id: "small" })}
              </th>
              <th
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {"4A"}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                className="member-cell col-2 text-nowrap text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                1st {intl.formatMessage({ id: "prize" })}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("big1", "big", "1")}
              >
                {isEditing === "big1" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.BIG).prize1 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("small1", "small", "1")}
              >
                {isEditing === "small1" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.SMALL).prize1 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("4A1", "4a", "1")}
              >
                {isEditing === "4A1" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize1 || ""
                )}
              </td>
            </tr>
            <tr>
              <td
                className="member-cell col-2 text-nowrap text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                2nd {intl.formatMessage({ id: "prize" })}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("big2", "big", "2")}
              >
                {isEditing === "big2" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.BIG).prize2 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("small2", "small", "2")}
              >
                {isEditing === "small2" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.SMALL).prize2 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("4A2", "4a", "2")}
              >
                {isEditing === "4A2" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize2 || ""
                )}
              </td>
            </tr>
            <tr>
              <td
                className="member-cell col-2 text-nowrap text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                3rd {intl.formatMessage({ id: "prize" })}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("big3", "big", "3")}
              >
                {isEditing === "big3" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.BIG).prize3 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("small3", "small", "3")}
              >
                {isEditing === "small3" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.SMALL).prize3 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("4A3", "4a", "3")}
              >
                {isEditing === "4A3" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize3 || ""
                )}
              </td>
            </tr>
            <tr>
              <td
                className="member-cell col-2 text-nowrap text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {intl.formatMessage({ id: "special" })}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("big4", "big", "4")}
              >
                {isEditing === "big4" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.BIG).prize4 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("small4", "small", "4")}
              >
                {isEditing === "small4" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.SMALL).prize4 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("4A4", "4a", "4")}
              >
                {isEditing === "4A4" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize4 || ""
                )}
              </td>
            </tr>
            <tr>
              <td
                className="member-cell col-2 text-nowrap text-start"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
              >
                {intl.formatMessage({ id: "consolation" })}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("big5", "big", "5")}
              >
                {isEditing === "big5" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.BIG).prize5 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("small5", "small", "5")}
              >
                {isEditing === "small5" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.SMALL).prize5 || ""
                )}
              </td>
              <td
                className="member-cell col-2 text-nowrap text-end"
                style={{ border: "1px solid rgba(175, 164, 164, 0.2)" }}
                onClick={() => handleFocus("4A5", "4a", "5")}
              >
                {isEditing === "4A5" ? (
                  <input
                    type="number"
                    className="input-css"
                    value={inputValue}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoFocus
                  />
                ) : (
                  getLotteryPrize(LotteryBetTypeConst.FOUR_A).prize5 || ""
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
