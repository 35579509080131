import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useRef, useState } from "react";
import * as actions from "../redux/TransactionAction";
import { callGroupCredit } from "../../main/redux/MainAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";
import { WithdrawApproveForm } from "./WithdrawApproveForm";
import { useAuth } from "../../auth/components/AuthInit";
import SweetAlert2 from "react-sweetalert2";

export const ApproveDialog = ({ itemId, fromPage }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const formikRef = useRef();
  const fileInputRef = useRef(null);
  const { auth } = useAuth();
  const imageName = Date.now();
  const [swalProps, setSwalProps] = useState({});

  const handleClick = () => {
    const queryParams = {
      action: "manualtopupupdate",
      selitemid: itemId,
      approvereject: "approve",
      message: "",
    };
    dispatch(actions.updateManualTopup(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "updateSuccess" }),
          onConfirm: () => {
            const queryParams = {
              action: "manualtopuprequestlist",
            };
            dispatch(actions.callATMTransactionList(queryParams));
            const params = {
              action: "groupcredit",
            };
            dispatch(callGroupCredit(params));
            dismissBtnRef.current.click();
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const saveValues = (queryParams) => {
    const newQueryParams = {
      action: "withdrawapprove",
      agentid: auth.agentid,
      loginid: auth.loginid,
      projectid: auth.projectid,
      selitemid: itemId,
      imagename: imageName,
    };

    const formData = new FormData();
    formData.append("memberid", imageName);
    formData.append("receiptPath", queryParams.receipt);
    formData.append("jsonData", JSON.stringify(newQueryParams));
    dispatch(actions.approveWithdrawList(formData)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "updateSuccess" }),
          onConfirm: () => {
            if (dismissBtnRef.current) {
              dismissBtnRef.current.click();
            }
            const queryParams = {
              action: "withdrawrequestlist",
            };
            dispatch(actions.callWithdrawList(queryParams));
            const params = {
              action: "groupcredit",
            };
            dispatch(callGroupCredit(params));
            formikRef.current.resetForm();
            if (fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <Modal
        modalId="ApproveModal"
        title={intl.formatMessage({ id: "approveTransaction" })}
        dismissBtnRef={dismissBtnRef}
      >
        {fromPage === "topup" ? (
          <>
            <div className="p-3 custom-border-bottom">
              {intl.formatMessage({ id: "confirmApprove" })}
            </div>
            <div className="p-3 d-flex justify-content-end align-items-center">
              <button
                className="btn btn-success btn-sm mx-1"
                type="submit"
                onClick={handleClick}
              >
                {intl.formatMessage({ id: "confirm" })}
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={handleCancel}
              >
                {intl.formatMessage({ id: "cancel" })}
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="p-3 custom-border-bottom">
              <WithdrawApproveForm
                formikRef={formikRef}
                saveValues={saveValues}
                clearFileInputRef={fileInputRef}
              />
            </div>
            <div className="p-3 d-flex justify-content-end align-items-center">
              <button
                className="btn btn-success btn-sm mx-1"
                type="button"
                onClick={() => formikRef.current.submitForm()}
              >
                {intl.formatMessage({ id: "confirm" })}
              </button>
              <button
                className="btn btn-danger btn-sm"
                type="button"
                onClick={handleCancel}
              >
                {intl.formatMessage({ id: "cancel" })}
              </button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
