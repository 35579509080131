import { useIntl } from "react-intl";
import { useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/AgentAction";
import { Modal } from "../../common/Modal";
import { useAuth } from "../../auth/components/AuthInit";
import { Switch } from "antd";
import Skeleton from "react-loading-skeleton";

export const AgentGameList = ({ selAgentId, selUsername }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const agentState = useSelector(({ agent }) => agent, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "agentgamelist",
      selagentid: selAgentId,
    };
    dispatch(actions.callAgentGameList(queryParams));
  }, [selAgentId]);
  const handleSwitchChange = (checked, gamecode) => {
    const updateQueryParams = {
      action: "updateagentgamelist",
      selagentid: selAgentId,
      gamecode: gamecode,
    };
    dispatch(actions.updateAgentStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "agentgamelist",
          selagentid: selAgentId,
        };
        dispatch(actions.callAgentGameList(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Agent status:", error);
      });
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="GameListModal"
        title={intl.formatMessage({ id: "gameList" })}
        subtitle={selUsername}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="agent-container">
          <div className="agent-header" style={{ display: "flex" }}>
            <div className="agent-cell" style={{ flex: "1" }}>
              {intl.formatMessage({ id: "no" })}
            </div>
            <div className="agent-cell" style={{ flex: "4" }}>
              {intl.formatMessage({ id: "gamename" })}
            </div>
            <div className="agent-cell" style={{ flex: "2" }}>
              {intl.formatMessage({ id: "status" })}
            </div>
          </div>
          <div className="agent-body">
            {agentState.agentGameLists &&
            agentState.agentGameLists.agentGameLists ? (
              agentState.agentGameLists.agentGameLists.length > 0 &&
              agentState.agentGameLists.agentGameLists.map((game, index) => (
                <div key={index} className="agent-row">
                  <div className="agent-cell" style={{ flex: "1" }}>
                    {index + 1}
                  </div>
                  <div className="agent-cell" style={{ flex: "4" }}>
                    {game.gamename}
                  </div>
                  <div className="agent-cell" style={{ flex: "2" }}>
                    <Switch
                      checked={game.statuscheck === "true"}
                      onChange={(checked) =>
                        handleSwitchChange(checked, game.gamecode)
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <Skeleton height="600px" className="w-100" />
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
