export const NavBarConst = {
  home: "/home",
  chat: "/chat",
  promotion: "/promotion",
  report: "/report",
  profile: "/profile",
};

export const SettingItem = {
  personal: "/setting/personal",
  pointShop: "/setting/pointShop",
  bonus: "/setting/bonus",
  withdraw: "/setting/withdraw",
  deposit: "/setting/deposit",
  memberTransfer: "/setting/memberTransfer",
  addBank: "/setting/addBank",
};
export const ForgetPassNavTitle = {
  PHONE: "phoneNo",
  GMAIL: "gmail",
};

export const AgentDetailsNavTitle = {
  INFO: "info",
  // ACCOUNT: "account",
};

export const AgentDownlineDetailsNavTitle = {
  GAMEREPORT: "gameReport",
  PLAYERREPORT: "memberReport",
};

export const LotteryTypeConst = {
  BASIC_LOTTERY: "MKT",
  HL_LOTTERY: "HL",
};
export const LotteryBetTypeConst = {
  BIG: "BIG",
  SMALL: "SMALL",
  FOUR_A: "4A",
};
