import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/PlatfromSettingAction";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { AddBankDialog } from "./AddBankDialog";
import { DeleteDialog } from "./Delete";

export const BankAccountList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [currentIndex, setCurrentIndex] = useState("");
  const [fromSide, setFromSide] = useState("");
  const [platformSettingPermission, setPlatformSettingPermission] =
    useState("");
  const dispatch = useDispatch();
  const platformSettingState = useSelector(
    ({ platformSetting }) => platformSetting,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "masterbankaccountlist",
    };
    dispatch(actions.callBankList(queryParams));
  }, []);
  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );

    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "platformsetting":
            setPlatformSettingPermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
  }, []);
  const customCardClass =
    "rounded align-items-center justify-content-spacebetween py-2 px-5";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <AddBankDialog setSwalProps={setSwalProps} />
      <DeleteDialog
        setSwalProps={setSwalProps}
        currentIndex={currentIndex}
        fromSide={fromSide}
      />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2>{intl.formatMessage({ id: "bankAccount" })}</h2>
        {platformSettingPermission === "true" && (
          <>
            <button
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target={`#addBankModal`}
            >
              {intl.formatMessage({ id: "addBank" })}
            </button>
          </>
        )}
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        {platformSettingState.bankAccountList &&
        platformSettingState.bankAccountList.masterBankAccountLists &&
        platformSettingState.bankAccountList.masterBankAccountLists.length >
          0 ? (
          platformSettingState.bankAccountList.masterBankAccountLists.map(
            (bank, index) => (
              <div
                className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
                style={{
                  border: "1px solid grey",
                  margin: "1vh 1vh",
                }}
                key={index}
              >
                <div className="py-2">{bank.bankname}</div>
                <div className="py-2">{bank.accountname}</div>
                <div className="py-2">{bank.accountnum}</div>
                {platformSettingPermission === "true" && (
                  <div className="py-2">
                    <button
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target={`#deleteModal`}
                      onClick={() => {
                        setCurrentIndex(bank.itemid);
                        setFromSide("bank");
                      }}
                    >
                      {intl.formatMessage({ id: "delete" })}
                    </button>
                  </div>
                )}
              </div>
            )
          )
        ) : (
          <div
            className={`${customCardClass} gamelistDiv1 cursor-pointer text-center`}
            style={{
              border: "1px solid grey",
              margin: "1vh 1vh",
            }}
          >
            {platformSettingPermission === "true" ? (
              <>
                <div
                  className="no-record-wrapper"
                  data-bs-toggle="modal"
                  data-bs-target={`#addBankModal`}
                >
                  <i className="bi bi-plus fs-1 mb-3" />
                  <div className="text-font2">
                    {intl.formatMessage({ id: "addBank" })}
                  </div>
                </div>
              </>
            ) : (
              <div className="no-record-wrapper oRecordDiv">
                <i className="bi bi-search fs-1 mb-3" />
                <div className="text-font2">
                  {intl.formatMessage({ id: "noRecordFound" })}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
