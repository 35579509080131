import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    errorOccurContactAdmin: "Error Occur. Please Contact Admin.",
    dashboard: "Dashboard",
    menu: "Menu",
    changePassword: "Change Password",
    profile: "Profile",
    report: "Report",
    companyWinLoss: "Company Win/Loss",
    agentReport: "Agent Report",
    lotteryPayout: "Lottery Payout",
    userList: "User List",
    memberList: "Member List",
    agentList: "Agent List",
    subAgentList: "Sub-Agent List",
    currencyExchange: "Currency Exchange",
    logout: "Logout",
    balance: "Balance",
    member: "Member",
    agent: "Agent",
    total: "Total",
    inviteLink: "Invite Link",
    dailyCommission: "Daily Commission",
    password: "Password",
    login: "Login",
    username: "Username",
    usernameRequired: "Username Required",
    passwordRequired: "Password Required",
    error: "Error",
    newPassword: "New Password",
    oldPassword: "Old Password",
    confirmPassword: "Confirm Password",
    oldPasswordRequired: "Old Password Required ",
    newPasswordRequired: "New Password Required",
    confirmPasswordRequired: "Confirm Password Required",
    submit: "Submit",
    addSubAgent: "Create Sub-Agent",
    addAgent: "Create Agent",
    addMember: "Create Member",
    noRecordFound: "No Record Found",
    search: "Search",
    no: "No.",
    nickname: "Nickname",
    inviteBy: "Invite By",
    status: "Status",
    action: "Action",
    currency: "Currency",
    registerDate: "Register Date",
    share: "Share",
    invalidConfirmPassword: "Invalid Confirm Password",
    changePasswordSuccess: "Password Change Successful",
    success: "Success",
    delete: "Delete",
    create: "Create",
    createSubAgent: "Create Sub-Agent",
    createSubAgentSuccess: "Create Sub-Agent Successful",
    edit: "Edit",
    editSubAgentSuccess: "Edit Sub-Agent Successful",
    editSubAgent: "Edit Sub-Agent",
    updateSubAgentSuccess: "Update Sub-Agent Successful",
    updateSubAgentPermission: "Sub-Agent Permission",
    update: "Update",
    details: "Details",
    editAgent: "Edit Agent",
    editSubAgentSuccess: "Edit Agent Successful",
    editMember: "Edit Member",
    editAgentSuccess: "Edit Member Successful",
    totalAgent: "Total Agent Downline",
    totalMember: "Total Member",
    inviteby: "Invite By",
    ipaddress: "IP Address",
    regdate: "Registration Date",
    createAgentSuccess: "Create Agent Successful",
    createAgent: "Create Agent",
    createMemberSuccess: "Create Member Successful",
    createMember: "Create Member",
    searchByUsername: "Search By Username",
    fail: "Fail",
    topupAndWithdraw: "Topup And Withdraw",
    topup: "Topup",
    withdraw: "Withdraw",
    amount: "Amount",
    invalidAmount: "Invalid Amount",
    topupSuccess: "Topup Successful",
    withdrawSuccess: "Withdraw Successful",
    gamename: "Game Name",
    close: "Close",
    gameList: "Game List",
    transfer: "Transfer",
    transferin: "Transfer In",
    transferout: "Transfer Out",
    gamerate: "Game Rate",
    companyname: "Company Name",
    transferInSuccess: "Transfer In Successful",
    transferOutSuccess: "Transfer Out Successful",
    showmore: "Show More",
    showless: "Show Less",
    create: "Create",
    gameurl: "Game Url",
    copySuccessfully: "Copy Successfully",
    accountList: "Account List",
    personal: "Personal",
    createGameIdSuccess: "Create Game ID Success",
    gameId: "Game ID",
    date: "Date",
    beforeAmount: "Before Amount",
    afterAmount: "After Amount",
    platfromSetting: "Platfrom Setting",
    gameActiveList: "Game Active List",
    agentDetails: "Agent Details",
    back: "Back",
    financialReport: "Financial Report",
    totalTopup: "Total Topup",
    totalWithdraw: "Total Withdraw",
    totalWin: "Total Win",
    totalBet: "Total Bet",
    totalProfit: "Total Profit",
    agentDownlineReport: "Win/Lose Report",
    transactionReport: "Transaction Report",
    searchUsername: "Search Username",
    memberDetails: "Member Details",
    memberReport: "Member Report",
    afBalance: "After Balance",
    bfBalance: "Before Balance",
    manualTopup: "Manual Topup ",
    gatewayTopup: "Payment Gateway Topup ",
    receiveFromUpline: "Receive From Upline ",
    telcoTopup: "Telco Topup ",
    transferToDownline: "Transfer To Downline ",
    withdrawByUpline: "Withdraw By Upline ",
    withdrawDownline: "Withdraw Downline ",
    transaction: "Transaction",
    atm: "ATM",
    topupRequest: "Topup Request",
    paymentGateway: "Payment Gateway",
    telco: "Telco",
    atmTransaction: "ATM Transaction",
    bankDetails: "Bank Details",
    receipt: "Receipt",
    approve: "Approve",
    decline: "Decline",
    approveTransaction: "Approve Transaction",
    confirmApprove: "Do you confirm to approve this transaction?",
    declineTransaction: "Decline Transaction",
    cancel: "Cancel",
    confirm: "Confirm",
    reason: "Reason",
    enterReason: "Enter Reason",
    paymentGatewayTransaction: "Payment Gateway Transaction",
    requestDate: "Request Date",
    respondDate: "Respond Date",
    gatewayStatus: "Gateway Status",
    telcoTransaction: "Telco Transaction",
    telcoStatus: "Telco Status",
    bankAccount: "Bank Account",
    addBank: "Add Bank",
    bankName: "Bank Name",
    accountName: "Account Name",
    accountNum: "Account Number",
    add: "Add",
    addBankSuccess: "Add Bank Successfully",
    deleteConfirmation: "Delete Confirmation",
    confirmDelete: "Do you confirm to delete?",
    deleteBankSuccess: "Delete Successfully",
    contactList: "Contact List",
    channelname: "Channel Name",
    channelurl: "Channel Url",
    telno: "Tel No.",
    addContactSuccess: "Add Contact Successfully",
    addContact: "Add Contact",
    contactList: "Contact List",
    deleteContactSuccess: "Delete Contact Successfully",
    gateway: "Gateway",
    es3: "ES3",
    checkId: "Check ID",
    mobileDetails: "Mobile Details",
    paymentMethod: "Payment Method",
    paymentMethodList: "Payment Method List",
    companyGameList: "Company Game List",
    companyName: "Company Name",
    agentFeeCharge: "Agent Fee Charge",
    methodName: "Method Name",
    chargeFee: "Charge Fee",
    markUp: "Mark Up",
    editmarkUp: "Edit Mark Up",
    deleteTelegramSuccess: "Delete Telegram Successfully",
    telegramList: "Telegram List",
    addTelegram: "Add Telegram",
    telegramId: "Telegram ID",
    telegramNoti: "Telegram Notification",
    addTelegramSuccess: "Add Telegram Successfully",
    editAgentFeeSuccess: "Edit Agent Fee Charge Successfully",
    withdrawRequest: "Withdraw Request",
    fullname: "Full Name",
    inputReason: "Please Input Reason",
    paymentGatewayTopup: "Payment Gateway Topup",
    topupCount: "Topup Count",
    withdrawCount: "Withdraw Count",
    onProgress: "On Progress",
    withdrawUpdateStatus: "Withdraw Status Update",
    reject: "Reject",
    updateSuccess: "Update Successfully",
    confirmOnProgress: "Do you confrim to progress this withdraw?",
    receiptRequired: "Receipt Required",
    visible: "Visible",
    createGameIdSuccess: "Create Game ID Successfully",
    confirmEdit: "Do you confirm to submit this edit?",
    editMemberSuccess: "Edit Member Successfully",
    agentDownlineDetails: "Win/Lose Details",
    reportAnalysis: "Report Analysis",
    agentLotteryList: "Agent Lottery List",
    vBossDetails: "VBoss Details",
    progressiveBet: "Progressive Bet",
    progressiveWin: "Progressive Win",
    total4DBet: "Total 4D Bet ",
    total4DWin: "Total 4D Win ",
    totalProgressiveBet: "Total Progressive Bet ",
    totalProgressiveWin: "Total Progressive Win ",
    connect: "Connect",
    submitDetailsSuccess: "Submit Details Successfully",
    connectSuccess: "Connect Successfully",
    connection: "Connection",
    connected: "Connected",
    notConnected: "Not Connected",
    totalMktBet: "Total MKT Bet",
    totalMktWin: "Total MKT Win",
    totalHlBet: "Total HL Bet",
    totalHlWin: "Total HL Win",
    manual: "Manual",
    refresh: "Refresh",
    active: "Active",
    inactive: "Inactive",
    projectCredit: "Project Credit",
    projectCreditWithdraw: "Project Credit Withdraw",
    telegramCode: "Telegram Code",
    withdrawAmount: "Withdraw Amount",
    history: "History",
    request: "Request",
    onprogress: "On Progress",
    days: "Days",
    submitWithdrawSuccess: "Submit Withdraw Details Successfully",
    withdrawLock: "Withdraw Lock",
    projectBalance: "Project Balance",
    alert: "Alert",
    confirmationCancelWithdraw: "Do you confirm to cancel this withdraw",
    withdrawRequestCancelSuccessfully: "Withdraw Request Cancel Successfully",
    totalIn: "Total In",
    totalOut: "Total Out",
    gamelog: "Game Log",
    requestedDate: "Requested Date",
    paymentOnHold: "Payment On Hold",
    paymentOnHoldList: "Payment On Hold List",
    totalInRemark:
      '*(Vboss-MKT/Vboss-HL) "Total In" is calculated based on the player\'s purchase.',
    totalOutRemark:
      '*(Vboss-MKT/Vboss-HL) "Total Out" is calculated based on the player\'s win, not transfers In/Out.',
    betDate: "Bet Date",
    gameName: "Game Name",
    gameType: "Game Type",
    gameSummaryReport: "Game Summary Report",
    totalGameIn: "Total Game In",
    totalGameOut: "Total Game Out",
    totalGameProfit: "Total Game Profit",
    total4dBet: "Total 4D Bet ",
    total4dWin: "Total 4D Win ",
    crtDownline: "Create Downline",
    acctquota: "Account Quota",
    acctQuota: "Acct. Quota",
    quota: "Quota",
    info: "Info",
    account: "Account",
    acctConnect: "Acct. Connect",
    acctType: "Acct. Type",
    acctDayCount: "Acct. Day Count",
    editAgentAcc: "Edit Agent Account",
    acctLastDate: "Acct. Last Date",
    agentAccWebSuccess: `Agent's account web updated`,
    setupPrize: "Setup Prize",
    lotterySetupPrize: "Lottery Setup Prize",
    MKT: "MKT",
    HL: "HL",
    prize: "Prize",
    big: "Big",
    small: "Small",
    consolation: "Consolation",
    special: "Special",
    gameIn: "Game In",
    gameOut: "Game Out ",
    gameProfit: "Game Profit ",
    netPurchase: "Net Purchase ",
    mktBet: "MKT Bet ",
    mktWin: "MKT Win",
    hlBet: "HL Bet ",
    hlWin: "HL Win ",
    DBet: "D Bet",
    DWin: "D Win",
    vboss: "V Boss",
    totalBuy: "Total Buy ",
    share: "Share",
    shareDetails: "Share Details",
    webUrl: "Web Url",
    topupHistory: "Topup History",
    withdrawHistory: "Withdraw History",
    sessionLost: "Session Lost. Please login again",
    gameReport: "Game Report",
    url: "URL",
    handleBy: "Handle By",
    all: "All",
    refundNPromotion: "Refund/Promotion",
    memberRefund: "Member Refund",
    refundBy: "Refund By",
    direction: "Direction",
    calculate: "Calculate",
    proceed: "Proceed",
    balanceInGame: "Balance In Game",
    refundBalance: "Refund Balance",
    condition: "Condition",
    confirmProceed: "Confirm Proceed",
    memberRefundHistory: "Member Refund History",
    memberCount: "Member Count",
    refundDate: "Refund Date",
    directionBaseOn: "Direction Base On",
    directionNum: "Direction Number",
    refundType: "Refund Type",
    refundNumber: "Refund Number",
    fromDate: "From Date",
    toDate: "To Date",
    totalRefund: "Total Refund",
    refundTopup: "Refund Topup",
    appDownload: "App Download",
    appleDownload: "Apple Download",
    androidDownload: "Android Download",
  },

  [LOCALES.CHINESE]: {},
  [LOCALES.MALAY]: {},
  [LOCALES.INDO]: {},
};
