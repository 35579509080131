import React, { useEffect, useState } from "react";
import "./sideBar.css";
import { useIntl } from "react-intl";
import Logo from "./Logo";
import DarkMode from "./DarkMode";
import { useAuth } from "../../auth/components/AuthInit";
import { useLocation, useNavigate } from "react-router-dom";

export default function SideBar() {
  const intl = useIntl();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [userRole, setUserRole] = useState("");
  const path = location.pathname;
  const [activeItem, setActiveItem] = useState("searchByUsername");
  const [memberTopupPermission, setMemberTopupPermission] = useState("");
  const [bonusPromoSetting, setBonusPromoSetting] = useState("");
  const [membersettingPermission, setMemberSettingPermission] = useState("");
  const [agentSettingPermission, setAgentSettingPermission] = useState("");
  const [creditControlPermission, setCreditControlPermission] = useState("");
  const [promotionEventPermission, setPromotionEventPermission] = useState("");
  const [memberBalancePermission, setMemberBalancePermission] = useState("");
  const [platformSettingPermission, setPlatformSettingPermission] =
    useState("");
  const [viewfcReportPermission, setViewfcReportPermission] = useState("");

  useEffect(() => {
    if (path.includes("/changePassword")) {
      setActiveItem("changePassword");
    } else if (path.includes("/profile")) {
      setActiveItem("profile");
    } else if (path.includes("/companyWinLose")) {
      setActiveItem("companyWinLose");
    } else if (path.includes("/agentDownlineReport")) {
      setActiveItem("agentDownlineReport");
    } else if (path.includes("/transactionReport")) {
      setActiveItem("transactionReport");
    } else if (path.includes("/gameSummaryReport")) {
      setActiveItem("gameSummaryReport");
    } else if (path.includes("/agentReport")) {
      setActiveItem("agentReport");
    } else if (path.includes("/lotteryPayout")) {
      setActiveItem("lotteryPayout");
    } else if (path.includes("/searchByUsername")) {
      setActiveItem("searchByUsername");
    } else if (path.includes("/home")) {
      setActiveItem("searchByUsername");
    } else if (path.includes("/agentList")) {
      setActiveItem("accountList");
    } else if (path.includes("/memberList")) {
      setActiveItem("accountList");
    } else if (path.includes("/accountList")) {
      setActiveItem("accountList");
    } else if (path.includes("/memberRefundHistory")) {
      setActiveItem("memberRefundHistory");
    } else if (path.includes("/memberRefund")) {
      setActiveItem("memberRefund");
    } else if (path.includes("/subAgentList")) {
      setActiveItem("subAgentList");
    } else if (path.includes("/currencyExchange")) {
      setActiveItem("currencyExchange");
    } else if (path.includes("/gameActiveList")) {
      setActiveItem("gameActiveList");
    } else if (path.includes("/agentReport")) {
      setActiveItem("agentReport");
    } else if (path.includes("/topupRequest")) {
      setActiveItem("topupRequest");
    } else if (path.includes("/topupHistory")) {
      setActiveItem("topupHistory");
    } else if (path.includes("/withdrawRequest")) {
      setActiveItem("withdrawRequest");
    } else if (path.includes("/withdrawHistory")) {
      setActiveItem("withdrawHistory");
    } else if (path.includes("/paymentOnHold")) {
      setActiveItem("paymentOnHold");
    } else if (path.includes("/telco")) {
      setActiveItem("telco");
    } else if (path.includes("/paymentGateway")) {
      setActiveItem("paymentGateway");
    } else if (path.includes("/bankAccount")) {
      setActiveItem("bankAccount");
    } else if (path.includes("/contactList")) {
      setActiveItem("contactList");
    } else if (path.includes("/paymentMethod")) {
      setActiveItem("paymentMethod");
    } else if (path.includes("/telegramList")) {
      setActiveItem("telegramList");
    } else if (path.includes("/appDownload")) {
      setActiveItem("appDownload");
    } else if (path.includes("/companyGameList")) {
      setActiveItem("companyGameList");
    } else if (path.includes("/dashboard")) {
      setActiveItem("dashboard");
    } else if (path.includes("/projectCredit")) {
      setActiveItem("projectCredit");
    } else {
      setActiveItem("searchByUsername");
    }
  }, [path]);
  const handleItemClick = (item) => {
    setActiveItem(item);
    if (window.innerWidth <= 768) {
      document.body.classList.toggle("toggle-sidebar");
    }
  };
  const handleClose = () => {
    document.body.classList.toggle("toggle-sidebar");
  };

  const handleLogout = async () => {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("press:logout");
      localStorage.clear();
      sessionStorage.clear();
    } else {
      await logout();
    }
  };
  useEffect(() => {
    const storedUserPermission = JSON.parse(
      localStorage.getItem("permissions")
    );
    const storedUserRole = localStorage.getItem("role");

    if (storedUserPermission) {
      storedUserPermission.forEach((permission) => {
        switch (permission.permissioncode) {
          case "membertopupwithdraw":
            setMemberTopupPermission(permission.allow);
            break;
          case "bonuspromosetting":
            setBonusPromoSetting(permission.allow);
            break;
          case "membersetting":
            setMemberSettingPermission(permission.allow);
            break;
          case "agentsetting":
            setAgentSettingPermission(permission.allow);
            break;
          case "creditcontrol":
            setCreditControlPermission(permission.allow);
            break;
          case "promotionevent":
            setPromotionEventPermission(permission.allow);
            break;
          case "memberbalancecontrol":
            setMemberBalancePermission(permission.allow);
            break;
          case "platformsetting":
            setPlatformSettingPermission(permission.allow);
            break;
          case "viewfcreport":
            setViewfcReportPermission(permission.allow);
            break;
          default:
            break;
        }
      });
    }
    if (storedUserRole) {
      setUserRole(storedUserRole);
    }
  }, []);
  return (
    <div className={"sidebar-container"}>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <div
            className="d-flex"
            style={{ margin: "5px", justifyContent: "space-between" }}
          >
            <Logo openfrom={"home"} />
            <i className="bi bi-x" onClick={() => handleClose()} />
          </div>
          <hr />
          <div
            className="d-flex"
            style={{ margin: "5px", justifyContent: "space-between" }}
          >
            <i
              className="bi bi-x"
              style={{ visibility: "hidden" }}
              onClick={() => handleClose()}
            />
            <DarkMode />
          </div>

          <li className="nav-item">
            <span>{intl.formatMessage({ id: "accountList" })}</span>
          </li>

          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("searchByUsername");
              navigate("/searchByUsername");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "searchByUsername" ? "#4154f1" : "",
                background: activeItem === "searchByUsername" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-search"></i>
              <span>{intl.formatMessage({ id: "searchByUsername" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("accountList");
              navigate("/accountList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "accountList" ? "#4154f1" : "",
                background: activeItem === "accountList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-person-fill-add"></i>
              <span>{intl.formatMessage({ id: "accountList" })}</span>
            </div>
          </li>
          {/* {userRole === "main" && (
            <li
              className="nav-item"
              onClick={() => {
                handleItemClick("projectCredit");
                navigate("/projectCredit");
              }}
            >
              <div
                className={"nav-link"}
                style={{
                  color: activeItem === "projectCredit" ? "#4154f1" : "",
                  background: activeItem === "projectCredit" ? "#f6f9ff" : "",
                }}
              >
                <i className="bi bi-card-list"></i>
                <span>{intl.formatMessage({ id: "projectCredit" })}</span>
              </div>
            </li>
          )} */}

          <li className="nav-item">
            <span>{intl.formatMessage({ id: "transaction" })}</span>
          </li>
          {memberTopupPermission === "true" && (
            <>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("topupRequest");
                  navigate("/transaction/topupRequest");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "topupRequest" ? "#4154f1" : "",
                    background: activeItem === "topupRequest" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-credit-card-2-back"></i>
                  <span>{intl.formatMessage({ id: "topupRequest" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("topupHistory");
                  navigate("/transaction/topupHistory");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "topupHistory" ? "#4154f1" : "",
                    background: activeItem === "topupHistory" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-postcard"></i>
                  <span>{intl.formatMessage({ id: "topupHistory" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("withdrawRequest");
                  navigate("/transaction/withdrawRequest");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "withdrawRequest" ? "#4154f1" : "",
                    background:
                      activeItem === "withdrawRequest" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-box-arrow-up"></i>
                  <span>{intl.formatMessage({ id: "withdrawRequest" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("withdrawHistory");
                  navigate("/transaction/withdrawHistory");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "withdrawHistory" ? "#4154f1" : "",
                    background:
                      activeItem === "withdrawHistory" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-postcard"></i>
                  <span>{intl.formatMessage({ id: "withdrawHistory" })}</span>
                </div>
              </li>
            </>
          )}
          {/* <li
            className="nav-item"
            onClick={() => {
              handleItemClick("paymentOnHold");
              navigate("/transaction/paymentOnHold");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "paymentOnHold" ? "#4154f1" : "",
                background: activeItem === "paymentOnHold" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-stop-circle"></i>
              <span>{intl.formatMessage({ id: "paymentOnHold" })}</span>
            </div>
          </li> */}
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("paymentGateway");
              navigate("/transaction/paymentGateway");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "paymentGateway" ? "#4154f1" : "",
                background: activeItem === "paymentGateway" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-globe"></i>
              <span>{intl.formatMessage({ id: "paymentGateway" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("telco");
              navigate("/transaction/telco");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "telco" ? "#4154f1" : "",
                background: activeItem === "telco" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-phone"></i>
              <span>{intl.formatMessage({ id: "telco" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "report" })}</span>
          </li>
          {/* <li
            className="nav-item"
            onClick={() => handleItemClick("companyWinLoss")}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "companyWinLoss" ? "#4154f1" : "",
                background: activeItem === "companyWinLoss" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-buildings"></i>
              <span>{intl.formatMessage({ id: "companyWinLoss" })}</span>
            </div>
          </li> */}
          {viewfcReportPermission === "true" && (
            <>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("dashboard");
                  navigate("/dashboard");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "dashboard" ? "#4154f1" : "",
                    background: activeItem === "dashboard" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-grid"></i>
                  <span>{intl.formatMessage({ id: "reportAnalysis" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("agentReport");
                  navigate("/agentReport");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "agentReport" ? "#4154f1" : "",
                    background: activeItem === "agentReport" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-clipboard2-data"></i>
                  <span>{intl.formatMessage({ id: "financialReport" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("agentDownlineReport");
                  navigate("/agentReport/agentDownlineReport");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color:
                      activeItem === "agentDownlineReport" ? "#4154f1" : "",
                    background:
                      activeItem === "agentDownlineReport" ? "#f6f9ff" : "",
                  }}
                >
                  <i className="bi bi-clipboard2-pulse"></i>
                  <span>
                    {intl.formatMessage({ id: "agentDownlineReport" })}
                  </span>
                </div>
              </li>
            </>
          )}
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("transactionReport");
              navigate("/agentReport/transactionReport");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "transactionReport" ? "#4154f1" : "",
                background: activeItem === "transactionReport" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-file-earmark-text"></i>
              <span>{intl.formatMessage({ id: "transactionReport" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("gameSummaryReport");
              navigate("/agentReport/gameSummaryReport");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "gameSummaryReport" ? "#4154f1" : "",
                background: activeItem === "gameSummaryReport" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-file-earmark-text"></i>
              <span>{intl.formatMessage({ id: "gameSummaryReport" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "refundNPromotion" })}</span>
          </li>
          {bonusPromoSetting === "true" && (
            <>
              {" "}
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("memberRefund");
                  navigate("/memberRefund");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color: activeItem === "memberRefund" ? "#4154f1" : "",
                    background: activeItem === "memberRefund" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-person-fill-add"></i>
                  <span>{intl.formatMessage({ id: "memberRefund" })}</span>
                </div>
              </li>
              <li
                className="nav-item"
                onClick={() => {
                  handleItemClick("memberRefundHistory");
                  navigate("/memberRefund/memberRefundHistory");
                }}
              >
                <div
                  className={"nav-link"}
                  style={{
                    color:
                      activeItem === "memberRefundHistory" ? "#4154f1" : "",
                    background:
                      activeItem === "memberRefundHistory" ? "#f6f9ff" : "",
                  }}
                  // href="/"
                >
                  <i className="bi bi-person-fill-add"></i>
                  <span>
                    {intl.formatMessage({ id: "memberRefundHistory" })}
                  </span>
                </div>
              </li>
            </>
          )}

          <li className="nav-item">
            <span>{intl.formatMessage({ id: "platfromSetting" })}</span>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("gameActiveList");
              navigate("/platformSetting/gameActiveList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "gameActiveList" ? "#4154f1" : "",
                background: activeItem === "gameActiveList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-ban"></i>
              <span>{intl.formatMessage({ id: "gameActiveList" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("bankAccount");
              navigate("/platformSetting/bankAccount");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "bankAccount" ? "#4154f1" : "",
                background: activeItem === "bankAccount" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-bank"></i>
              <span>{intl.formatMessage({ id: "bankAccount" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("contactList");
              navigate("/platformSetting/contactList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "contactList" ? "#4154f1" : "",
                background: activeItem === "contactList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-person-lines-fill"></i>
              <span>{intl.formatMessage({ id: "contactList" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("paymentMethod");
              navigate("/platformSetting/paymentMethod");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "paymentMethod" ? "#4154f1" : "",
                background: activeItem === "paymentMethod" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-coin"></i>
              <span>{intl.formatMessage({ id: "paymentMethod" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("companyGameList");
              navigate("/platformSetting/companyGameList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "companyGameList" ? "#4154f1" : "",
                background: activeItem === "companyGameList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-controller"></i>
              <span>{intl.formatMessage({ id: "companyGameList" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("telegramList");
              navigate("/platformSetting/telegramList");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "telegramList" ? "#4154f1" : "",
                background: activeItem === "telegramList" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-telegram"></i>
              <span>{intl.formatMessage({ id: "telegramList" })}</span>
            </div>
          </li>
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("appDownload");
              navigate("/platformSetting/appDownload");
            }}
          >
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "appDownload" ? "#4154f1" : "",
                background: activeItem === "appDownload" ? "#f6f9ff" : "",
              }}
              // href="/"
            >
              <i className="bi bi-download"></i>
              <span>{intl.formatMessage({ id: "appDownload" })}</span>
            </div>
          </li>
          <li className="nav-item">
            <span>{intl.formatMessage({ id: "personal" })}</span>
          </li>
          {userRole === "main" && (
            <li
              className="nav-item"
              onClick={() => {
                handleItemClick("subAgentList");
                navigate("/subAgentList");
              }}
            >
              <div
                className={"nav-link"}
                style={{
                  color: activeItem === "subAgentList" ? "#4154f1" : "",
                  background: activeItem === "subAgentList" ? "#f6f9ff" : "",
                }}
                // href="/"
              >
                <i className="bi bi-person"></i>
                <span>{intl.formatMessage({ id: "subAgentList" })}</span>
              </div>
            </li>
          )}
          <li
            className="nav-item"
            onClick={() => {
              handleItemClick("changePassword");
              navigate("/changePassword");
            }}
          >
            <div
              className="nav-link"
              style={{
                color: activeItem === "changePassword" ? "#4154f1" : "",
                background: activeItem === "changePassword" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-pen"></i>
              <span>{intl.formatMessage({ id: "changePassword" })}</span>
            </div>
          </li>
          {/* 
          <li className="nav-item" onClick={() => handleItemClick("profile")}>
            <div
              className={"nav-link"}
              style={{
                color: activeItem === "profile" ? "#4154f1" : "",
                background: activeItem === "profile" ? "#f6f9ff" : "",
              }}
            >
              <i className="bi bi-person"></i>
              <span>{intl.formatMessage({ id: "profile" })}</span>
            </div>
          </li> */}
          <li className="nav-item">
            <div className="nav-logout" onClick={handleLogout}>
              <i className="bi bi-box-arrow-left"></i>
              <span>{intl.formatMessage({ id: "logout" })}</span>
            </div>
          </li>
        </ul>
      </aside>
    </div>
  );
}
