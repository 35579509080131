import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const refundNPromotionState = {
  memberRefundList: null,
  memberRefundProcess: null,
  memberRefundHistory: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const refundNPromotionSlice = createSlice({
  name: "refundNPromotion",
  initialState: refundNPromotionState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    memberRefundListFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberRefundList = action.payload.data ?? {};
    },
    memberRefundProcessFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberRefundProcess = action.payload.data ?? {};
    },
    memberRefundHistoryFetch: (state, action) => {
      state.actionsLoading = false;
      state.memberRefundHistory = action.payload.data ?? {};
    },
  },
});
