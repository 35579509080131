import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../common/control/InputField";

export const ProjectCreditForm = ({ formikRef, saveValues, balance }) => {
  const intl = useIntl();
  return (
    <Formik
      initialValues={{
        bankname: "",
        accountname: "",
        accountnum: "",
        withdrawamount: "",
        telegramcode: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        let queryParams = {
          action: "creditwithdrawrequest",
          bankname: values.bankname,
          accountname: values.accountname,
          accountnum: values.accountnum,
          withdrawamount: values.withdrawamount,
          telegramcode: values.telegramcode,
        };
        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            {intl.formatMessage({ id: "projectBalance" }) + " : " + balance}
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "bankName" })}
            </label>
            <Field
              name="bankname"
              component={Input}
              placeholder={intl.formatMessage({ id: "bankName" })}
              autoComplete="off"
              type="text"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "accountName" })}
            </label>
            <Field
              name="accountname"
              component={Input}
              placeholder={intl.formatMessage({ id: "accountName" })}
              autoComplete="off"
              type="text"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "accountNum" })}
            </label>
            <Field
              name="accountnum"
              component={Input}
              placeholder={intl.formatMessage({ id: "accountNum" })}
              autoComplete="off"
              type="number"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "withdrawAmount" })}
            </label>
            <Field
              name="withdrawamount"
              component={Input}
              placeholder="0.00"
              autoComplete="off"
              type="number"
            />
          </div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "telegramCode" })}
            </label>
            <Field
              name="telegramCode"
              component={Input}
              placeholder={intl.formatMessage({ id: "telegramCode" })}
              autoComplete="off"
              type="text"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
