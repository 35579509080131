import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ProjectCreditAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { useAuth } from "../../auth/components/AuthInit";
import {
  HistoryStatusColorConst,
  ProjectHistoryConst,
  historyDurationList,
} from "../../../constants/ProjectCreditConst";
import SweetAlert2 from "react-sweetalert2";

export const ProjectCreditHistory = ({}) => {
  const intl = useIntl();
  const formikRef = useRef();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [dayType, setDayType] = useState(1);
  const [selectedNav, setSelectedNav] = useState(ProjectHistoryConst.REQUEST);
  const accountCreditState = useSelector(
    ({ accountCredit }) => accountCredit,
    shallowEqual
  );

  useEffect(() => {
    const queryParams = {
      action: "creditwithdrawlist",
      days: dayType,
    };
    dispatch(actions.callAccountCreditList(queryParams));
  }, [dayType]);

  const cancelWithdrawRequest = (itemid) => {
    setSwalProps({
      show: true,
      icon: "info",
      title: intl.formatMessage({ id: "alert" }),
      text: intl.formatMessage({
        id: "confirmationCancelWithdraw",
      }),
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonText: intl.formatMessage({ id: "confirm" }),
      denyButtonText: intl.formatMessage({ id: "cancel" }),
      onResolve: () => {
        setSwalProps({});

        const queryParams = {
          action: "creditwithdrawcancel",
          selitemid: itemid,
        };
        dispatch(actions.creditWithdrawCancel(queryParams)).then((resp) => {
          resp = resp.data;

          if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
            const queryParams = {
              action: "creditwithdrawlist",
              days: dayType,
            };
            dispatch(actions.callAccountCreditList(queryParams));
            setSwalProps({
              show: true,
              icon: "success",
              title: intl.formatMessage({ id: "success" }),
              text: intl.formatMessage({
                id: "withdrawRequestCancelSuccessfully",
              }),
            });
          } else {
            setSwalProps({
              show: true,
              icon: "error",
              title: intl.formatMessage({ id: "error" }),
              text: resp.message,
            });
          }
        });
      },
    });
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div className="d-flex">
        {Object.entries(ProjectHistoryConst).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "#0080FF" : "",
              border: selectedNav === value ? "2px solid #0080FF" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3 text-nowrap"
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>
      <div style={{ margin: "0 auto" }}>
        <div className="col-md-1">
          <select
            className="form-control text-font3"
            value={dayType}
            onChange={(e) => setDayType(e.target.value)}
          >
            {historyDurationList.map((day, index) => (
              <option key={index} value={day}>
                {day} {intl.formatMessage({ id: "days" })}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div>
        {accountCreditState &&
        accountCreditState.accountCreditList &&
        accountCreditState.accountCreditList.creditWithdrawLists &&
        accountCreditState.accountCreditList.creditWithdrawLists.length > 0 ? (
          accountCreditState.accountCreditList.creditWithdrawLists
            .filter(
              (withdraw) =>
                withdraw.status.toLowerCase() === selectedNav.toLowerCase()
            )
            .map((withdrawList, index) => (
              <div
                key={index}
                className="card mb-3 card-css"
                style={{ width: "100%" }}
              >
                <div className="card-body">
                  {/* <div className="row">
                    <div className="col-12 col-md-3 mb-2">
                      {intl.formatMessage({ id: "bankName" }) +
                        " : " +
                        withdrawList.bankname}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      {intl.formatMessage({ id: "accountName" }) +
                        " : " +
                        withdrawList.accountname}
                    </div>
                    <div className="col-12 col-md-3 mb-2">
                      {intl.formatMessage({ id: "accountNum" }) +
                        " : " +
                        withdrawList.accountnum}
                    </div>
                  </div> */}

                  <div className="row d-flex justify-content-between">
                    <div className="col-3 mb-2 text-nowrap">
                      {intl.formatMessage({ id: "withdrawAmount" }) + " : "}
                      <strong>{withdrawList.withdrawamount}</strong>
                    </div>
                    <div
                      className={`col-3 py-1 px-2 rounded small fw-bold text-dark text-center text-nowrap`}
                      style={{
                        background:
                          HistoryStatusColorConst[withdrawList.status],
                      }}
                    >
                      {withdrawList.status}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div
                        className="fst-italic small text-font2"
                        style={{ color: "grey" }}
                      >
                        {intl.formatMessage({ id: "requestedDate" }) +
                          " : " +
                          withdrawList.requestdate}
                      </div>
                      {(withdrawList.status.toLowerCase() ===
                        ProjectHistoryConst.APPROVE.toLowerCase() ||
                        withdrawList.status.toLowerCase() ===
                          ProjectHistoryConst.REJECT.toLowerCase()) && (
                        <div
                          className="fst-italic small text-font2"
                          style={{ color: "grey" }}
                        >
                          {intl.formatMessage({ id: "rejectReason" }) +
                            " : " +
                            withdrawList.message}
                        </div>
                      )}
                    </div>
                    {withdrawList.status.toLowerCase() ===
                    ProjectHistoryConst.REQUEST.toLowerCase() ? (
                      <button
                        className="btn btn-danger cursor-pointer text-dark px-4 py-1 fw-bold mt-2"
                        onClick={() =>
                          cancelWithdrawRequest(withdrawList.itemid)
                        }
                      >
                        {intl.formatMessage({ id: "cancel" })}
                      </button>
                    ) : withdrawList.status.toLowerCase() ===
                      ProjectHistoryConst.APPROVE.toLowerCase() ? (
                      <div
                        className="position-relative cursor-pointer custom-border rounded px-2 me-1 text-font2"
                        onClick={() => {
                          setSwalProps({
                            show: true,
                            showCloseButton: true,
                            showConfirmButton: false,
                            html: `<div><h4 class='d-flex align-items-center justify-content-start mb-4'>${intl.formatMessage(
                              {
                                id: "prove",
                              }
                            )}</h4><img src='${
                              withdrawList.receiptimage
                            }' class="w-100"/></div>`,
                          });
                        }}
                      >
                        <i
                          className="bi bi-image fs-1 p-0"
                          title={intl.formatMessage({ id: "prove" })}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))
        ) : (
          <div className="card mb-3 card-css" style={{ width: "100%" }}>
            <div className="card-body">
              <div className="row">
                <div className="no-record-wrapper oRecordDiv">
                  <i className="bi bi-search fs-1 mb-3" />
                  <div className="text-font2">
                    {intl.formatMessage({ id: "noRecordFound" })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
