import { authFormPost, authPost, doPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkGameSuspendList(params) {
  return authPost(`${baseUrl}/Agent5.ashx`, params);
}
export function checkPlatformList(params) {
  return authPost(`${baseUrl}/Agent6.ashx`, params);
}
export function checkPlatformSetting(params) {
  return authPost(`${baseUrl}/Agent7.ashx`, params);
}
export function checkDownloadList(params) {
  return authPost(`${baseUrl}/Agent11.ashx`, params);
}
