import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { MemberRefund } from "../../modules/refundNPromotion/components/MemberRefund";
import { MemberRefundHistory } from "../../modules/refundNPromotion/components/MemberRefundHistory";

const RefundNPromotionContext = createContext();

const RefundNPromotionWrapper = () => {
  const value = {};

  return (
    <RefundNPromotionContext.Provider value={value}>
      <Routes>
        <Route path="" element={<MemberRefund />} />
        <Route path="/memberRefundHistory" element={<MemberRefundHistory />} />
      </Routes>
    </RefundNPromotionContext.Provider>
  );
};

export { RefundNPromotionWrapper };
