import { authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkMemberList(params) {
  return authPost(`${baseUrl}/Agent2.ashx`, params);
}

export function checkMemberGameList(params) {
  return authPost(`${baseUrl}/Agent4.ashx`, params);
}
export function checkMemberReportList(params) {
  return authPost(`${baseUrl}/Agent5.ashx`, params);
}
export function checkMemberrTelcoNGatewayList(params) {
  return authPost(`${baseUrl}/Agent6.ashx`, params);
}
export function changeMemberPassword(params) {
  return authPost(`${baseUrl}/Agent8.ashx`, params);
}
export function memberGameLogReport(params) {
  return authPost(`${baseUrl}/Agent9.ashx`, params);
}
export function vBossReport(params) {
  return authPost(`${baseUrl}/Agent10.ashx`, params);
}
