import { useIntl } from "react-intl";
import { useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PlatfromSettingAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";

export const DeleteDialog = ({ setSwalProps, currentIndex, fromSide }) => {
  const intl = useIntl();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();

  const handleCancel = () => {
    if (dismissBtnRef.current) {
      dismissBtnRef.current.click();
    }
  };
  const handleConfirm = () => {
    if (fromSide == "bank") {
      const queryParams = {
        action: "removemasterbankaccount",
        itemid: `${currentIndex}`,
      };
      dispatch(actions.deleteBankList(queryParams)).then((resp) => {
        resp = resp.data;
        if (dismissBtnRef.current) {
          dismissBtnRef.current.click();
        }
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "deleteBankSuccess" }),
            onConfirm: () => {
              const queryParams = {
                action: "masterbankaccountlist",
              };
              dispatch(actions.callBankList(queryParams));
              dismissBtnRef.current.click();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    } else if (fromSide == "contact") {
      const queryParams = {
        action: "removemastercontact",
        itemid: `${currentIndex}`,
      };
      dispatch(actions.deleteContactList(queryParams)).then((resp) => {
        resp = resp.data;
        if (dismissBtnRef.current) {
          dismissBtnRef.current.click();
        }
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "deleteContactSuccess" }),
            onConfirm: () => {
              const queryParams = {
                action: "mastercontactlist",
              };
              dispatch(actions.callContactList(queryParams));
              dismissBtnRef.current.click();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    } else if (fromSide == "telegram") {
      const queryParams = {
        action: "removetelegramid",
        receiveid: `${currentIndex}`,
      };
      dispatch(actions.deleteTelegramList(queryParams)).then((resp) => {
        resp = resp.data;
        if (dismissBtnRef.current) {
          dismissBtnRef.current.click();
        }
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setSwalProps({
            show: true,
            icon: "success",
            title: intl.formatMessage({ id: "success" }),
            text: intl.formatMessage({ id: "deleteTelegramSuccess" }),
            onConfirm: () => {
              const queryParams = {
                action: "telegramlist",
              };
              dispatch(actions.callTelegramList(queryParams));
              dismissBtnRef.current.click();
            },
          });
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    }
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="deleteModal"
        title={intl.formatMessage({ id: "deleteConfirmation" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          {intl.formatMessage({ id: "confirmDelete" })}
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          <button
            className="btn btn-success btn-sm mx-1"
            onClick={handleConfirm}
          >
            {intl.formatMessage({ id: "confirm" })}
          </button>
          <button className="btn btn-danger btn-sm" onClick={handleCancel}>
            {intl.formatMessage({ id: "cancel" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
