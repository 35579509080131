import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { Modal } from "../../common/Modal1";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { formatInTimeZone } from "date-fns-tz";

export const AgentDownlineMember = ({
  selMemberId,
  selMemberName,
  fromDate,
  toDate,
  memberReportDetailsVisible,
  onClose,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";

  useEffect(() => {
    const queryParams = {
      action: "membergamereport",
      selmemberid: selMemberId,
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callAgentDownlineMemberDetails(queryParams));
  }, [selMemberId]);

  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="agentDownlineMemberReportModal"
        title={intl.formatMessage({ id: "memberDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={memberReportDetailsVisible}
        onClose={onClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <span className="mx-3">{selMemberName}</span>
        <div className="member-div d-flex" style={{ margin: "10px auto" }}>
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2 text-start text-nowrap">
                      {intl.formatMessage({ id: "companyName" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameIn" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameOut" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameProfit" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {reportState.agentDownlineMemberDetailsLists &&
                    reportState.agentDownlineMemberDetailsLists
                      .memberGameReports &&
                    reportState.agentDownlineMemberDetailsLists
                      .memberGameReports.length > 0 &&
                    reportState.agentDownlineMemberDetailsLists.memberGameReports.map(
                      (report, index) => (
                        <tr key={index} className="member-row1">
                          <td className="member-cell hide-on-mobile col-1">
                            {index + 1}
                          </td>
                          <td className="member-cell col-2 text-start">
                            {report.companyname}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalgamein}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalgameout}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalgameprofit}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
                <tfoot>
                  {reportState &&
                    reportState.agentDownlineMemberDetailsLists && (
                      <tr className="member-row1">
                        <td className="member-cell hide-on-mobile col-1"></td>
                        <td className="member-cell col-3 text-end">
                          {intl.formatMessage({ id: "total" }) + " : "}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberDetailsLists
                              .totalgamein
                          }
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberDetailsLists
                              .totalgameout
                          }
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberDetailsLists
                              .totalgameprofit
                          }
                        </td>
                      </tr>
                    )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
