import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input, Input4 } from "../../common/control/InputField";
import "./agentList.css";

export const TopupForm = ({
  formikRef,
  saveValues,
  selAgentId,
  selUsername,
  selNickname,
  selBalance,
  selectedItem,
  selQuota,
}) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        amount: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        if (selectedItem == "balance") {
          if (values.amount != null || values.amount != "0") {
            let queryParams = {
              action: "agenttopupagent",
              selagentid: selAgentId,
              amount: values.amount,
            };
            saveValues(queryParams);
          } else {
            formikHelpers.setFieldError(
              "amount",
              intl.formatMessage({ id: "invalidAmount" })
            );
          }
        } else if (selectedItem == "acctQuota") {
          if (values.amount != null || values.amount != "0") {
            let queryParams = {
              action: "agentacctquotatopup",
              selagentid: selAgentId,
              quota: values.amount,
            };
            saveValues(queryParams);
          } else {
            formikHelpers.setFieldError(
              "amount",
              intl.formatMessage({ id: "invalidAmount" })
            );
          }
        }
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div>
            {intl.formatMessage({ id: "username" }) + " : " + selUsername}
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div>
                {intl.formatMessage({ id: "nickname" }) + " : " + selNickname}
              </div>
              {selectedItem === "balance" ? (
                <div>
                  {intl.formatMessage({ id: "balance" }) + " : " + selBalance}
                </div>
              ) : (
                <div>
                  {intl.formatMessage({ id: "acctQuota" }) + " : " + selQuota}
                </div>
              )}
            </div>
          </div>
          <div
            className="my-2"
            style={{ borderBottom: "1px solid rgb(175, 164, 164, 0.2)" }}
          ></div>
          <div className="mb-2">
            <label className="mb-2 text-input-label">
              {intl.formatMessage({ id: "amount" })}
            </label>
            {selectedItem === "balance" ? (
              <Field
                name="amount"
                component={Input}
                placeholder="0.00"
                autoComplete="off"
                type="number"
              />
            ) : (
              <Field
                name="amount"
                component={Input4}
                placeholder="0"
                autoComplete="off"
                type="number"
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};
