import { authFormPost, authPost } from "../../common/redux/ApiClient";

const baseUrl = "35.240.204.237/es3KioskApi";

export function checkTransactionList(params) {
  return authPost(`${baseUrl}/Agent6.ashx`, params);
}
export function checkWithdrawList(params) {
  return authPost(`${baseUrl}/Agent8.ashx`, params);
}
export function withdrawApproveList(params) {
  return authFormPost(
    `${window.location.protocol}//media.es51.com/api/withdraw.php`,
    params
  );
}
export function checkOnHoldList(params) {
  return authPost(`${baseUrl}/Agent9.ashx`, params);
}
export function checkRespondList(params) {
  return authPost(`${baseUrl}/Agent10.ashx`, params);
}
