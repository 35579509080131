import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const AgentReportList = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  // const [fromDate, setFromDate] = useState(subDays(new Date(), 7));
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";

  useEffect(() => {
    const timeZone = "Asia/Kuala_Lumpur";
    const queryParams = {
      action: "agentcompanyreportV2",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };
    dispatch(actions.callAgentReportList(queryParams));
  }, [dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };
  const handleSearchClick = () => {
    const queryParams = {
      action: "agentcompanyreportV2",
      fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
      todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
    };

    dispatch(actions.callAgentReportList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };
  const customCardClass =
    "rounded d-flex align-items-center justify-content-spacebetween py-2";

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "financialReport" })}</h2>
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      <div>
        {reportState && reportState.agentReportLists && (
          <div
            className={`${customCardClass} gamelistDiv1 cursor-pointer position-relative text-center mobile-overflow`}
            style={{
              border: "1px solid grey",
              padding: "2px",
              margin: "10px 0 ",
            }}
          >
            <div style={{ width: "100%" }}>
              <table
                className="text-start table-report"
                style={{
                  width: "100%",
                  minWidth: "1000px",
                }}
              >
                <tbody>
                  <tr
                    style={{
                      borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                    }}
                  >
                    <td className="col-3  td-line">
                      {intl.formatMessage({ id: "totalTopup" }) +
                        " : " +
                        reportState.agentReportLists.totaltopup}
                    </td>
                    <td className="col-3 td-line">
                      {intl.formatMessage({ id: "manualTopup" }) +
                        " : " +
                        reportState.agentReportLists.manualtopup}
                    </td>
                    <td className="col-3 td-line">
                      {intl.formatMessage({ id: "paymentGatewayTopup" }) +
                        " : " +
                        reportState.agentReportLists.paymentgatewaytopup}
                    </td>
                    <td className="col-3 td-line">
                      {intl.formatMessage({ id: "refundTopup" }) +
                        " : " +
                        reportState.agentReportLists.telcotopup}
                    </td>
                  </tr>
                  <tr
                    style={{
                      borderBottom: "1px solid rgba(175, 164, 164, 0.4)",
                    }}
                  >
                    <td className="col-3 td-line">
                      {intl.formatMessage({ id: "totalWithdraw" }) +
                        " : " +
                        reportState.agentReportLists.totalwithdraw}
                    </td>
                    <td className="col-3 td-line"></td>
                    <td className="col-3 td-line"></td>
                  </tr>
                  <tr>
                    <td className="col-3 td-line">
                      {intl.formatMessage({ id: "netPurchase" }) +
                        " : " +
                        reportState.agentReportLists.netpurchase}
                    </td>
                    <td className="col-3 td-line"></td>
                    <td className="col-3 td-line"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      <div className="mobile-fontSize" style={{ color: "#FF8000" }}>
        <div>{intl.formatMessage({ id: "totalInRemark" })}</div>
        <div>{intl.formatMessage({ id: "totalOutRemark" })}</div>
      </div>
      {/* <div>
          {reportState && reportState.agentReportLists && (
            <div>
              <div>
                {intl.formatMessage({ id: "totalBet" }) +
                  " : " +
                  reportState.agentReportLists.totalbet}
              </div>
              <div>
                {intl.formatMessage({ id: "totalWin" }) +
                  " : " +
                  reportState.agentReportLists.totalwin}
              </div>
              <div>
                {intl.formatMessage({ id: "totalProfit" }) +
                  " : " +
                  reportState.agentReportLists.totalprofit}
              </div>
            </div>
          )}
        </div> */}
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2 text-start text-nowrap">
                    {intl.formatMessage({ id: "companyName" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "gameIn" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "gameOut" })}
                  </th>
                  <th className="member-cell col-2 text-end text-nowrap">
                    {intl.formatMessage({ id: "gameProfit" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-end text-nowrap">
                    {intl.formatMessage({ id: "progressiveBet" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-end">
                    {intl.formatMessage({ id: "progressiveWin" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {reportState.agentReportLists &&
                reportState.agentReportLists.agentCompanyReportsV2 &&
                reportState.agentReportLists.agentCompanyReportsV2.length >
                  0 ? (
                  reportState.agentReportLists.agentCompanyReportsV2.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-start">
                          {report.companyname}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.totalin}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.totalout}
                        </td>
                        <td
                          className="member-cell col-2 text-end"
                          style={{
                            color: report.totalprofit.includes("-")
                              ? "red"
                              : "green",
                          }}
                        >
                          {report.totalprofit}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.progressivebet}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.progressivewin}
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="7">
                      <div className="no-record-wrapper oRecordDiv">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                {reportState && reportState.agentReportLists && (
                  <tr
                    className="member-row1"
                    style={{ borderTop: "1px solid rgba(175, 164, 164, 0.4)" }}
                  >
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td className="member-cell col-2 text-end">
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentReportLists.totalin}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentReportLists.totalout}
                    </td>
                    <td
                      className="member-cell col-2 text-end"
                      style={{
                        color:
                          reportState.agentReportLists.totalprofit.includes("-")
                            ? "red"
                            : "green",
                      }}
                    >
                      {reportState.agentReportLists.totalprofit}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentReportLists.totalprogressivebet}
                    </td>
                    <td className="member-cell col-2 text-end">
                      {reportState.agentReportLists.totalprogressivewin}
                    </td>
                  </tr>
                )}
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
