import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/ReportAction";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { AgentDownlineDetailsNavTitle } from "../../../constants/NavBarConst";
import { AgentDownlineMember } from "./AgentDownlineMember";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const AgentDownlineDetailsList = () => {
  const { selagentID } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const location = useLocation();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(new Date(location.state?.fromDate));
  const [toDate, setToDate] = useState(new Date(location.state?.toDate));
  const [currentAgentID, setCurrentAgentID] = useState("");
  const [currentAgent, setCurrentAgent] = useState("");
  const [currentDownline, setCurrentDownline] = useState("");
  const [selMemberName, setSelMemberName] = useState(null);
  const [selectedNav, setSelectedNav] = useState(
    AgentDownlineDetailsNavTitle.GAMEREPORT
  );
  const [memberReportDetailsVisible, setMemberReportDetailsVisible] =
    useState(false);
  const [selMemberId, setSelMemberId] = useState(null);
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const timeZone = "Asia/Kuala_Lumpur";

  useEffect(() => {
    const fromDateStr = location.state?.fromDate;
    const toDateStr = location.state?.toDate;
    const currentAgentId = location.state?.currentAgentID;
    const currentAgent = location.state?.currentAgent;
    const currentDownline = location.state?.currentDownline;
    const backTo = location.state?.backTo || "gameReport";

    setCurrentDownline(currentDownline);
    setCurrentAgentID(currentAgentId);
    setCurrentAgent(currentAgent);
    setSelectedNav(backTo);
    if (fromDateStr && toDateStr) {
      const fromDateParsed = new Date(fromDateStr);
      const toDateParsed = new Date(toDateStr);

      if (!isNaN(fromDateParsed) && !isNaN(toDateParsed)) {
        setFromDate(fromDateParsed);
        setToDate(toDateParsed);
        const queryParams = {
          action: "agentfcgamereportV2",
          fromdate: formatInTimeZone(fromDateParsed, timeZone, "yyyy-MM-dd"),
          todate: formatInTimeZone(toDateParsed, timeZone, "yyyy-MM-dd"),
          selagentid: selagentID,
        };
        const queryParams2 = {
          action: "membergamesumreportV2",
          fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
          todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
          selagentid: selagentID,
        };

        dispatch(actions.agentDownlineDetails(queryParams));
        dispatch(actions.agentDownlineMemberReport(queryParams2));
      } else {
        console.error("Invalid date format received:", {
          fromDateStr,
          toDateStr,
        });
      }
    }
  }, [selagentID, location.state, dispatch]);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchClick = () => {
    setSelMemberId(null);
    if (selectedNav == "gameReport") {
      const queryParams = {
        action: "agentfcgamereportV2",
        fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
        todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
        selagentid: selagentID,
      };

      dispatch(actions.agentDownlineDetails(queryParams)).then((resp) => {
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    } else {
      const queryParams = {
        action: "membergamesumreportV2",
        fromdate: formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd"),
        todate: formatInTimeZone(toDate, timeZone, "yyyy-MM-dd"),
        selagentid: selagentID,
      };

      dispatch(actions.agentDownlineMemberReport(queryParams));
    }
  };
  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => setSwalProps({})}
        {...swalProps}
      />
      <AgentDownlineMember
        selMemberId={selMemberId}
        selMemberName={selMemberName}
        fromDate={fromDate}
        toDate={toDate}
        memberReportDetailsVisible={memberReportDetailsVisible}
        onClose={() => setMemberReportDetailsVisible(false)}
      />
      <div className="d-flex align-items-center">
        <h2>
          <i
            className="bi bi-chevron-left"
            onClick={() => {
              navigate(`/agentReport/agentDownlineReport`, {
                state: {
                  currentAgentID: currentAgentID,
                  currentAgent: currentAgent,
                  fromDate: fromDate.toISOString(),
                  toDate: toDate.toISOString(),
                },
              });
            }}
          ></i>
        </h2>
        <h2>{intl.formatMessage({ id: "agentDownlineDetails" })}</h2>
      </div>

      {/* <div>
        {currentDownline.includes("Personal") ? (
          <span>{currentAgent}</span>
        ) : (
          <>
            {currentAgent === currentDownline ? (
              <span>{currentAgent}</span>
            ) : (
              <>
                <div>{currentAgent}</div>
                <div>{"(" + currentDownline + ")"}</div>
              </>
            )}
          </>
        )}
      </div> */}
      <div>
        {currentAgent === currentDownline ? (
          <span>{currentAgent}</span>
        ) : (
          <>
            <div>{currentAgent}</div>
            <div>{"(" + currentDownline + ")"}</div>
          </>
        )}
      </div>
      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      <div className="d-flex">
        {Object.entries(AgentDownlineDetailsNavTitle).map(([key, value]) => (
          <h6
            style={{
              color: selectedNav === value ? "#0080FF" : "",
              borderBottom: selectedNav === value ? "2px solid #0080FF" : "",
              padding: "10px",
              cursor: "pointer",
            }}
            className="text-font3"
            onClick={() => setSelectedNav(value)}
            key={key}
          >
            {intl.formatMessage({ id: value })}
          </h6>
        ))}
      </div>
      {selectedNav === "gameReport" ? (
        <div className="member-div d-flex" style={{ margin: "10px auto" }}>
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2 text-start text-nowrap">
                      {intl.formatMessage({ id: "companyName" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameIn" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameOut" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameProfit" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "progressiveBet" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "progressiveWin" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {reportState.agentDownlineDetailsLists &&
                    reportState.agentDownlineDetailsLists.agentFcCompaniesV2 &&
                    reportState.agentDownlineDetailsLists.agentFcCompaniesV2
                      .length > 0 &&
                    reportState.agentDownlineDetailsLists.agentFcCompaniesV2.map(
                      (report, index) => (
                        <tr key={index} className="member-row1">
                          <td className="member-cell hide-on-mobile col-1">
                            {index + 1}
                          </td>
                          <td className="member-cell col-2 text-start">
                            {report.companyname}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalin}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalout}
                          </td>
                          <td
                            className="member-cell col-2 text-end"
                            style={{
                              color: report.totalprofit.includes("-")
                                ? "red"
                                : "green",
                            }}
                          >
                            {report.totalprofit}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.progressivebet}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.progressivewin}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
                <tfoot>
                  {reportState && reportState.agentDownlineDetailsLists && (
                    <tr className="member-row1">
                      <td className="member-cell hide-on-mobile col-1"></td>
                      <td className="member-cell col-3 text-end">
                        {intl.formatMessage({ id: "total" }) + " : "}
                      </td>
                      <td className="member-cell col-2 text-end">
                        {reportState.agentDownlineDetailsLists.totalin}
                      </td>
                      <td className="member-cell col-2 text-end">
                        {reportState.agentDownlineDetailsLists.totalout}
                      </td>
                      <td
                        className="member-cell col-2 text-end"
                        style={{
                          color:
                            reportState.agentDownlineDetailsLists.totalprofit.includes(
                              "-"
                            )
                              ? "red"
                              : "green",
                        }}
                      >
                        {reportState.agentDownlineDetailsLists.totalprofit}
                      </td>
                      <td className="member-cell col-2 text-end">
                        {
                          reportState.agentDownlineDetailsLists
                            .totalprogressivebet
                        }
                      </td>
                      <td className="member-cell col-2 text-end">
                        {
                          reportState.agentDownlineDetailsLists
                            .totalprogressivewin
                        }
                      </td>
                    </tr>
                  )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="member-div d-flex" style={{ margin: "10px auto" }}>
          <div className="member-container">
            <div className="table-responsive">
              <table className="member-table">
                <thead className="member-header1">
                  <tr className="member-row1">
                    <th className="member-cell hide-on-mobile col-1">
                      {intl.formatMessage({ id: "no" })}
                    </th>
                    <th className="member-cell col-2 text-start text-nowrap">
                      {intl.formatMessage({ id: "username" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {4 + intl.formatMessage({ id: "DBet" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {4 + intl.formatMessage({ id: "DWin" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameIn" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameOut" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "gameProfit" })}
                    </th>
                    <th className="member-cell col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "action" })}
                    </th>
                  </tr>
                </thead>
                <tbody className="member-body">
                  {reportState &&
                    reportState.agentDownlineMemberReportLists &&
                    reportState.agentDownlineMemberReportLists
                      .memberGameSummaryReports &&
                    reportState.agentDownlineMemberReportLists
                      .memberGameSummaryReports.length > 0 &&
                    reportState.agentDownlineMemberReportLists.memberGameSummaryReports.map(
                      (report, index) => (
                        <tr key={index} className="member-row1">
                          <td className="member-cell hide-on-mobile col-1">
                            {index + 1}
                          </td>
                          <td className="member-cell col-2 text-start">
                            {report.username}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.total4dbet}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.total4dwin}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalgamein}
                          </td>
                          <td className="member-cell col-2 text-end">
                            {report.totalgameout}
                          </td>
                          <td
                            className="member-cell col-2 text-end"
                            style={{
                              color: report.totalgameprofit.includes("-")
                                ? "red"
                                : "green",
                            }}
                          >
                            {report.totalgameprofit}
                          </td>
                          <td>
                            <button
                              className="btn btn-primary mx-1"
                              onClick={() => {
                                setSelMemberId(report.memberid);
                                setSelMemberName(report.username);
                                // setMemberReportDetailsVisible(true);
                                navigate(
                                  `/memberList/memberReport/${report.memberid}`,
                                  {
                                    state: {
                                      userName: report.username,
                                      currentAgent: currentAgent,
                                      fromPage: "yes",
                                      fromDate: fromDate,
                                      toDate: toDate,
                                      currentAgentId: selagentID,
                                      currentDownline: currentDownline,
                                    },
                                  }
                                );
                              }}
                            >
                              <i className="bi bi-exclamation-circle" />
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
                <tfoot>
                  {reportState &&
                    reportState.agentDownlineMemberReportLists && (
                      <tr className="member-row1">
                        <td className="member-cell hide-on-mobile col-1"></td>
                        <td className="member-cell col-3 text-end">
                          {intl.formatMessage({ id: "total" }) + " : "}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberReportLists
                              .total4dbet
                          }
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberReportLists
                              .total4dwin
                          }
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberReportLists
                              .totalgamein
                          }
                        </td>
                        <td className="member-cell col-2 text-end">
                          {
                            reportState.agentDownlineMemberReportLists
                              .totalgameout
                          }
                        </td>
                        <td
                          className="member-cell col-2 text-end"
                          style={{
                            color:
                              reportState.agentDownlineMemberReportLists.totalgameprofit.includes(
                                "-"
                              )
                                ? "red"
                                : "green",
                          }}
                        >
                          {
                            reportState.agentDownlineMemberReportLists
                              .totalgameprofit
                          }
                        </td>
                      </tr>
                    )}
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
