import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { TopupRequestList } from "../../modules/transaction/components/TopupRequest";
import { PaymentGatewayTransactionList } from "../../modules/transaction/components/PaymentGateway";
import { TelcoTransactionList } from "../../modules/transaction/components/Telco";
import { WithdrawRequestList } from "../../modules/transaction/components/WithdrawRequest";
import { PaymentOnHoldList } from "../../modules/transaction/components/PaymentOnHold";
import { TopupRespondList } from "../../modules/transaction/components/TopupRespond";
import { WithdrawRespondList } from "../../modules/transaction/components/WithdrawRespond";

const TransactionContext = createContext();

const TransactionWrapper = () => {
  const value = {};

  return (
    <TransactionContext.Provider value={value}>
      <Routes>
        <Route path="/topupRequest" element={<TopupRequestList />} />
        <Route
          path="/paymentGateway"
          element={<PaymentGatewayTransactionList />}
        />
        <Route path="/telco" element={<TelcoTransactionList />} />
        <Route path="/withdrawRequest" element={<WithdrawRequestList />} />
        <Route path="/paymentOnHold" element={<PaymentOnHoldList />} />
        <Route path="/topupHistory" element={<TopupRespondList />} />
        <Route path="/withdrawHistory" element={<WithdrawRespondList />} />
      </Routes>
    </TransactionContext.Provider>
  );
};

export { TransactionWrapper };
