import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import "./memberList.css";
import * as actions from "../redux/MemberAction";
import { callAgentList } from "../../agentlist/redux/AgentAction";
import { useParams } from "react-router-dom";
import { Switch } from "antd";
import { EditMemberDialog } from "./EditMemberDialog";
import { Modal } from "../../common/Modal1";

export const MemberList = ({
  selMemberId,
  currentAgentId,
  currentMemberPage,
  isMemberDetailsVisible,
  memberSettingPermission,
  onClose,
}) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const memberState = useSelector(({ member }) => member, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "memberdetail",
      memberid: selMemberId,
    };
    dispatch(actions.callMemberList(queryParams));
  }, [selMemberId]);

  useEffect(() => {
    if (memberState && memberState.memberLists) {
      setPassword(memberState.memberLists.password);
      setNickname(memberState.memberLists.nickname);
    }
  }, [memberState]);

  const handleSwitchChange = (checked, member) => {
    const updateQueryParams = {
      action: "updatememberstatus",
      memberid: member,
    };
    dispatch(actions.updateMemberStatus(updateQueryParams))
      .then(() => {
        const queryParams = {
          action: "memberdetail",
          memberid: selMemberId,
        };
        dispatch(actions.callMemberList(queryParams));
      })
      .catch((error) => {
        console.error("Error updating Member status:", error);
      });
  };
  const handleNicknameChange = (e) => {
    setNickname(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleClose = () => {
    onClose();
    const queryParams = {
      action: "agentmemberlist",
      selagentid: currentAgentId,
      agentpagenum: "0",
      memberpagenum: currentMemberPage - 1,
    };
    dispatch(callAgentList(queryParams));
  };
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="memberDetailsModal"
        title={intl.formatMessage({ id: "memberDetails" })}
        dismissBtnRef={dismissBtnRef}
        isVisible={isMemberDetailsVisible}
        onClose={handleClose}
      >
        <SweetAlert2
          customClass="custom-swal"
          onResolve={() => {
            setSwalProps({});
          }}
          {...swalProps}
        />
        <EditMemberDialog
          setSwalProps={setSwalProps}
          memberId={selMemberId}
          nickname={nickname}
          password={password}
          isVisible={isEditModalVisible}
          onClose={() => setEditModalVisible(false)}
        />

        <div>
          <div
            className="d-flex"
            style={{
              overflowX: "auto",
              margin: "10px auto",
              justifyContent: "center",
              justifyItems: "center",
            }}
          >
            <div className="member-container">
              <div className="member-header">
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "username" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "nickname" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "password" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "ipaddress" })}
                </div>
                <div className="member-cell text-start ">
                  {intl.formatMessage({ id: "inviteby" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "status" })}
                </div>
                <div className="member-cell text-start">
                  {intl.formatMessage({ id: "regdate" })}
                </div>
              </div>
              <div className="member-middle">
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
                <div className="member-cell">:</div>
              </div>
              <div className="member-body1">
                {memberState && memberState.memberLists ? (
                  <div>
                    <div className="member-cell text-start">
                      {memberState.memberLists.username}
                    </div>
                    {editButton === true ? (
                      <>
                        <div className="member-cell text-start">
                          <input
                            type="text"
                            style={{
                              borderColor: "rgba(175, 164, 164, 0.4)",
                              borderRadius: "5px",
                            }}
                            placeholder={memberState.memberLists.nickname}
                            onChange={handleNicknameChange}
                          />
                        </div>
                        <div className="member-cell text-start">
                          <input
                            type="text"
                            style={{
                              borderColor: "rgba(175, 164, 164, 0.4)",
                              borderRadius: "5px",
                            }}
                            placeholder={memberState.memberLists.password}
                            onChange={handlePasswordChange}
                            onInput={(e) => {
                              e.target.value = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                            }}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="member-cell text-start">
                          {memberState.memberLists.nickname}
                        </div>
                        <div className="member-cell text-start">
                          {memberState.memberLists.password}
                        </div>
                      </>
                    )}
                    <div className="member-cell text-start">
                      {memberState.memberLists.ipaddress === ""
                        ? "-"
                        : memberState.memberLists.ipaddress}
                    </div>
                    <div className="member-cell text-start">
                      {memberState.memberLists.inviteby}
                    </div>
                    {memberSettingPermission === "true" ? (
                      <>
                        <div className="member-cell text-start">
                          <Switch
                            checked={
                              memberState.memberLists.status === "active"
                            }
                            onChange={(checked) =>
                              handleSwitchChange(
                                checked,
                                memberState.memberLists.memberid
                              )
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="member-cell text-start">
                          {memberState.memberLists.status}
                        </div>
                      </>
                    )}
                    <div className="member-cell text-start">
                      {memberState.memberLists.regdate}
                    </div>
                  </div>
                ) : (
                  <Skeleton height="600px" className="w-100" />
                )}
              </div>
            </div>
          </div>
          {memberSettingPermission === "true" &&
            (editButton === true ? (
              <div className="p-3 d-flex justify-content-evenly">
                <button
                  className="btn btn-success px-3"
                  onClick={() => {
                    setEditModalVisible(true);
                  }}
                >
                  {intl.formatMessage({ id: "submit" })}
                </button>
                <button
                  className="btn btn-danger px-3"
                  onClick={() => {
                    setEditButton(false);
                  }}
                >
                  {intl.formatMessage({ id: "cancel" })}
                </button>
              </div>
            ) : (
              <div className="editButton p-3">
                <button
                  className="btn btn-primary px-3"
                  onClick={() => {
                    setEditButton(true);
                  }}
                >
                  {intl.formatMessage({ id: "edit" })}
                </button>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

// import { useState } from "react";
// import { useIntl } from "react-intl";
// import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { useEffect } from "react";
// import Skeleton from "react-loading-skeleton";
// import SweetAlert2 from "react-sweetalert2";
// import "./memberList.css";

// export const MemberList = () => {
//   const intl = useIntl();
//   const [swalProps, setSwalProps] = useState({});
//   const dispatch = useDispatch();
//   const settingState = useSelector(({ setting }) => setting, shallowEqual);

//   useEffect(() => {
//     // dispatch(actions.getWithdrawList({ }));
//   }, []);

//   //   const cancelWithdrawRequest = (itemid) => {
//   //     setSwalProps({
//   //       show: true,
//   //       icon: "info",
//   //       title: intl.formatMessage({ id: "alert" }),
//   //       text: intl.formatMessage({
//   //         id: "confirmationCancelWithdraw",
//   //       }),
//   //       showCancelButton: true,
//   //       showConfirmButton: true,
//   //       confirmButtonText: intl.formatMessage({ id: "confirm" }),
//   //       denyButtonText: intl.formatMessage({ id: "cancel" }),
//   //       onResolve: () => {
//   //         setSwalProps({});

//   //         dispatch(actions.cancelWithdrawRequest({ selitemid: itemid })).then(
//   //           (resp) => {
//   //             resp = resp.data;

//   //             if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
//   //               dispatch(actions.getWithdrawList({ days: dayType }));
//   //               dispatch(masterActions.getWalletInfo());

//   //               setSwalProps({
//   //                 show: true,
//   //                 icon: "success",
//   //                 title: intl.formatMessage({ id: "success" }),
//   //                 text: intl.formatMessage({
//   //                   id: "withdrawRequestCancelSuccessfully",
//   //                 }),
//   //               });
//   //             } else {
//   //               setSwalProps({
//   //                 show: true,
//   //                 icon: "error",
//   //                 title: intl.formatMessage({ id: "error" }),
//   //                 text: resp.message,
//   //               });
//   //             }
//   //           }
//   //         );
//   //       },
//   //     });
//   //   };

//   return (
//     <div style={{ width: "95%", margin: "5px auto" }}>
//       <SweetAlert2
//         customClass="custom-swal"
//         onResolve={() => {
//           setSwalProps({});
//         }}
//         {...swalProps}
//       />
//       <div
//         className="d-flex"
//         style={{
//           overflowX: "auto",
//           margin: "10px auto",
//         }}
//       >
//         <div className="member-div">
//           <div className="d-flex member-div2">
//             <div className="d-flex">
//               <input
//                 type="text"
//                 className="form-control me-2"
//                 placeholder={intl.formatMessage({ id: "username" })}
//               />
//               <select className="form-select">
//                 <option value="">MYR</option>
//                 <option value="option1">IDR</option>
//               </select>
//             </div>
//             <div>
//               <button
//                 className="btn btn-primary"
//                 // onClick={onSearch}
//               >
//                 {intl.formatMessage({ id: "search" })}
//               </button>
//             </div>
//           </div>

//           <table className="member-table">
//             <thead>
//               <tr className="member-tr">
//                 <th>{intl.formatMessage({ id: "no" })}</th>
//                 <th>{intl.formatMessage({ id: "username" })}</th>
//                 <th>{intl.formatMessage({ id: "nickname" })}</th>
//                 <th>{intl.formatMessage({ id: "inviteBy" })}</th>
//                 <th>{intl.formatMessage({ id: "status" })}</th>
//                 <th>{intl.formatMessage({ id: "action" })}</th>
//               </tr>
//             </thead>
//             <tbody>
//               {-1 > 0 ? (
//                 <div>
//                   {settingState.transactionReport.memberReports.map(
//                     (report, index) => (
//                       <tr key={index}>
//                         <td>{row.username}</td>
//                         <td>{row.nickname}</td>
//                         <td>{row.status}</td>
//                         <td>
//                           <button onClick={() => handleAction(index)}>
//                             Delete
//                           </button>
//                         </td>
//                       </tr>
//                     )
//                   )}
//                 </div>
//               ) : (
//                 <td colspan="6">
//                   <div className="d-flex py-2 align-items-center justify-content-center noRecorddiv">
//                     <div className="d-flex align-items-center justify-content-center flex-column">
//                       <i className="bi bi-search fs-1 mb-3" />
//                       <div className="text-font2">
//                         {intl.formatMessage({ id: "noRecordFound" })}
//                       </div>
//                     </div>
//                   </div>
//                 </td>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// };
