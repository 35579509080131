import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { GameActiveList } from "../../modules/platformSetting/components/GameActiveList";
import { BankAccountList } from "../../modules/platformSetting/components/BankAccount";
import { ContactList } from "../../modules/platformSetting/components/ContactList";
import { PaymentMethodList } from "../../modules/platformSetting/components/PaymentMethodList";
import { CompanyGameList } from "../../modules/platformSetting/components/CompanyGameList";
import { TelegramList } from "../../modules/platformSetting/components/TelegramList";
import { AppDownload } from "../../modules/platformSetting/components/AppDownload";

const PlatformSettingContext = createContext();

const PlatformSettingWrapper = () => {
  const value = {};

  return (
    <PlatformSettingContext.Provider value={value}>
      <Routes>
        <Route path="/gameActiveList" element={<GameActiveList />} />
        <Route path="/bankAccount" element={<BankAccountList />} />
        <Route path="/contactList" element={<ContactList />} />
        <Route path="/paymentMethod" element={<PaymentMethodList />} />
        <Route path="/companyGameList" element={<CompanyGameList />} />
        <Route path="/telegramList" element={<TelegramList />} />
        <Route path="/appDownload" element={<AppDownload />} />
      </Routes>
    </PlatformSettingContext.Provider>
  );
};

export { PlatformSettingWrapper };
