import { Route, Routes } from "react-router-dom";
import { createContext } from "react";
import { AgentReportList } from "../../modules/report/components/AgentReport";
import { AgentDownlineReportList } from "../../modules/report/components/AgentDownlineReport";
import { AgentDownlineDetailsList } from "../../modules/report/components/AgentDownlineDetails";
import { TransactionReportList } from "../../modules/report/components/TransactionReport";
import { GameSummaryReportList } from "../../modules/report/components/GameSummaryReport";

const AgentReportContext = createContext();

const AgentReportWrapper = () => {
  const value = {};

  return (
    <AgentReportContext.Provider value={value}>
      <Routes>
        <Route path="/" element={<AgentReportList />} />
        <Route
          path="/agentDownlineReport"
          element={<AgentDownlineReportList />}
        />
        <Route
          path="/agentDownlineReport/:selagentID"
          element={<AgentDownlineDetailsList />}
        />
        <Route path="/transactionReport" element={<TransactionReportList />} />
        <Route path="/gameSummaryReport" element={<GameSummaryReportList />} />
      </Routes>
    </AgentReportContext.Provider>
  );
};

export { AgentReportWrapper };
