const AUTH_LOCAL_STORAGE_KEY = "UserData";

const getAgentId = () => {
  const auth = getAuth();
  if (auth) {
    return auth["agentid"];
  }
  return;
};

const getLoginId = () => {
  const auth = getAuth();
  if (auth) {
    return auth["loginid"];
  }
  return;
};

const getProjectid = () => {
  const auth = getAuth();
  if (auth) {
    return auth["projectid"];
  }
  return;
};

const getCurrencyCode = () => {
  const auth = getAuth();
  if (auth) {
    return auth["currencycode"];
  }
  return;
};
const getDomainCode = () => {
  const auth = getAuth();
  if (auth) {
    return auth["weburl"];
  }
  return;
};

// const getLanguage = () => {
//   const auth = getAuth();
//   if (auth) {
//     return auth["language"];
//   }
//   return;
// };

const getAuth = () => {
  if (!localStorage) {
    return;
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (!lsValue) {
    return;
  }

  try {
    const auth = JSON.parse(lsValue);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(auth);
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};
// const getParam=()=>{
//   if (queryString) {
//     const urlParams = new URLSearchParams(queryString);
//     const checkid = urlParams.get('model')

//   }
// }
export {
  getAuth,
  setAuth,
  removeAuth,
  getAgentId,
  getLoginId,
  getCurrencyCode,
  getDomainCode,
  getProjectid,
  //   getLanguage,
  AUTH_LOCAL_STORAGE_KEY,
};
