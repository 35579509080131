import { Field, Form, Formik } from "formik";
import { useIntl } from "react-intl";
import { Input } from "../../common/control/InputField";

export const AddTelegramForm = ({ formikRef, saveValues }) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={{
        receiveid: "",
      }}
      innerRef={formikRef}
      enableReinitialize={true}
      onSubmit={(values, formikHelpers) => {
        let queryParams = {
          action: "addtelegramid",
          receiveid: values.receiveid,
        };
        saveValues(queryParams);
      }}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
      }) => (
        <Form>
          <div className="mb-2">
            <label className="mb-2 text-input-label" style={{ color: "white" }}>
              {intl.formatMessage({ id: "telegramId" })}
            </label>

            <Field
              name="receiveid"
              component={Input}
              placeholder={intl.formatMessage({ id: "telegramId" })}
              autoComplete="off"
              type="text"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};
