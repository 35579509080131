import { useIntl } from "react-intl";
import { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/RefundNPromotionAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { Modal } from "../../common/Modal";

export const ProceedDialog = ({ setSwalProps, encrypytString }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const dismissBtnRef = useRef();
  const [password, setPassword] = useState("");
  const refundNPromotionState = useSelector(
    ({ refundNPromotion }) => refundNPromotion,
    shallowEqual
  );

  const handleProceedClick = () => {
    const queryParams = {
      action: "memberrefundprocess",
      password: `${password}`,
      encrypytstring: `${encrypytString}`,
    };
    dispatch(actions.memberRefundProcess(queryParams)).then((resp) => {
      resp = resp.data;
      setPassword("");
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "createAgentSuccess" }),
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleModalClose = () => {
    setPassword("");
  };
  useEffect(() => {
    const dismissBtn = dismissBtnRef.current;
    if (dismissBtn) {
      dismissBtn.addEventListener("click", handleModalClose);
      return () => {
        dismissBtn.removeEventListener("click", handleModalClose);
      };
    }
  }, [dismissBtnRef]);
  return (
    <div style={{ width: "100%", margin: "0 auto" }}>
      <Modal
        modalId="proceedModal"
        title={intl.formatMessage({ id: "confirmProceed" })}
        dismissBtnRef={dismissBtnRef}
      >
        <div className="p-3 custom-border-bottom">
          <input
            type="text"
            className="proceed-Input"
            placeholder={intl.formatMessage({ id: "password" })}
            onChange={handlePasswordChange}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            }}
            value={password}
          />
        </div>
        <div className="p-3 d-flex justify-content-end align-items-center">
          {refundNPromotionState.actionsLoading && (
            <div
              className="spinner-border me-2"
              role="status"
              style={{ scale: "0.55" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
          <button
            type="button"
            disabled={refundNPromotionState.actionsLoading}
            className="btn btn-primary px-3"
            onClick={handleProceedClick}
          >
            {intl.formatMessage({ id: "proceed" })}
          </button>
        </div>
      </Modal>
    </div>
  );
};
