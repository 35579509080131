export const ProjectCreditConst = {
  WITHDRAW: "withdraw",
  HISTORY: "history",
};

export const ProjectHistoryConst = {
  REQUEST: "request",
  ONPROGRESS: "onprogress",
  CANCEL: "cancel",
  APPROVE: "approve",
  REJECT: "reject",
};

export const historyDurationList = [1, 7, 15, 30];

export const HistoryStatusColorConst = {
  REQUEST: "#00FFFF",
  ONPROGRESS: "#FFAC1C",
  PENDING: "#FFAC1C",
  APPROVE: "#FFA500",
  SUCCESS: "#FFA500",
  REJECT: "#EE4B2B",
  FAIL: "#EE4B2B",
  CANCEL: "#880808",
};
