import { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import * as actions from "../redux/TransactionAction";
import { useNavigate } from "react-router-dom";
import DateRangeComp from "../../common/DateRangeComp";
import { subDays, format } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { useAuth } from "../../auth/components/AuthInit";
import "../../memberlist/components/MemberReport.css";
import { CopyToClipboard } from "../../../utils/CommonHelper";

export const TelcoTransactionList = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const transactionState = useSelector(
    ({ transaction }) => transaction,
    shallowEqual
  );
  const timeZone = "Asia/Kuala_Lumpur";
  useEffect(() => {
    const formattedFromDate = formatInTimeZone(
      fromDate,
      timeZone,
      "yyyy-MM-dd"
    );
    const formattedToDate = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "telcoreport",
      fromdate: formattedFromDate,
      todate: formattedToDate,
    };
    dispatch(actions.callTelcoList(queryParams));
  }, []);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  const handleSearchButtonClick = () => {
    const formattedStart = formatInTimeZone(fromDate, timeZone, "yyyy-MM-dd");
    const formattedEnd = formatInTimeZone(toDate, timeZone, "yyyy-MM-dd");
    const queryParams = {
      action: "telcoreport",
      fromdate: formattedStart,
      todate: formattedEnd,
    };
    dispatch(actions.callTelcoList(queryParams));
  };

  const getStatusClass = (status) => {
    if (!status) return "";
    switch (status.toLowerCase()) {
      case "reject":
        return "bg-danger text-white";
      case "pending":
        return "bg-warning text-dark";
      case "approve":
        return "bg-success text-white";
      default:
        return "";
    }
  };

  return (
    <div style={{ width: "98%", margin: "5px auto" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div>
        <h2>{intl.formatMessage({ id: "telcoTransaction" })}</h2>
      </div>

      <div className="dateSearch-div">
        <div className="dateRange-div">
          <DateRangeComp
            initialRange={[
              { startDate: fromDate, endDate: toDate, key: "selection" },
            ]}
            onDateChange={handleDateChange}
          />
        </div>
        <div>
          <button
            className="btn btn-primary"
            onClick={() => handleSearchButtonClick()}
          >
            {intl.formatMessage({ id: "search" })}
          </button>
        </div>
      </div>
      <div className="my-2">
        {transactionState && transactionState.telcoTransactionLists && (
          <div>
            {intl.formatMessage({ id: "totalTopup" }) +
              " : " +
              transactionState.telcoTransactionLists.totaltopup}
          </div>
        )}
      </div>
      <div
        className="member-div d-flex"
        style={{
          margin: "10px auto",
        }}
      >
        <div className="member-container">
          <div className="table-responsive">
            <table className="member-table">
              <thead className="member-header1">
                <tr className="member-row1">
                  <th className="member-cell hide-on-mobile col-1">
                    {intl.formatMessage({ id: "no" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "requestDate" })}
                  </th>
                  <th className="member-cell col-2">
                    {intl.formatMessage({ id: "respondDate" })}
                  </th>
                  <th className="member-cell col-2 text-end">
                    {intl.formatMessage({ id: "amount" })}
                  </th>
                  <th className="member-cell col-3 text-start">
                    {intl.formatMessage({ id: "mobileDetails" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "username" })}
                  </th>
                  <th className="member-cell col-2 text-start">
                    {intl.formatMessage({ id: "status" })}
                  </th>
                  <th className="member-cell col-2 text-nowrap text-start">
                    {intl.formatMessage({ id: "telcoStatus" })}
                  </th>
                </tr>
              </thead>
              <tbody className="member-body">
                {transactionState.telcoTransactionLists &&
                transactionState.telcoTransactionLists.telcoReports &&
                transactionState.telcoTransactionLists.telcoReports.length >
                  0 ? (
                  transactionState.telcoTransactionLists.telcoReports.map(
                    (report, index) => (
                      <tr key={index} className="member-row1">
                        <td className="member-cell hide-on-mobile col-1">
                          {index + 1}
                        </td>
                        <td className="member-cell col-2 text-nowrap">
                          {report.requestdate}
                        </td>
                        <td className="member-cell col-2 text-nowrap">
                          {report.responsedate}
                        </td>
                        <td className="member-cell col-2 text-end">
                          {report.amount}
                        </td>
                        <td className="member-cell col-3 text-start">
                          <div className="d-flex align-items-center">
                            {report.telcoid}
                            <i
                              className="bi bi-copy mx-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                if (
                                  sessionStorage.getItem("platform") ===
                                  "mobile"
                                ) {
                                  console.log(`clipboard: ${report.telcoid}`);
                                  CopyToClipboard(
                                    report.gatewayid,
                                    setSwalProps,
                                    intl
                                  );
                                } else {
                                  CopyToClipboard(
                                    report.telcoid,
                                    setSwalProps,
                                    intl
                                  );
                                }
                              }}
                            />
                          </div>
                          <div>{report.mobilename}</div>
                          <div>{report.telconame}</div>
                        </td>
                        <td className="member-cell col-2 text-start ">
                          {report.username}
                        </td>
                        <td className="member-cell col-2 text-start">
                          {report.status}
                        </td>
                        <td className={`member-cell col-2 text-start `}>
                          <div
                            className={`${getStatusClass(
                              report.telcostatus
                            )} py-1 text-center rounded`}
                          >
                            {report.telcostatus}
                          </div>
                        </td>
                      </tr>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan="6">
                      <div className="no-record-wrapper">
                        <i className="bi bi-search fs-1 mb-3" />
                        <div className="text-font2">
                          {intl.formatMessage({ id: "noRecordFound" })}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
              {/* <tfoot>
                {reportState && reportState.transactionReportLists && (
                  <tr className="member-row1">
                    <td className="member-cell hide-on-mobile col-1"></td>
                    <td
                      className="member-cell col-3"
                      style={{ textAlign: "end" }}
                    >
                      {intl.formatMessage({ id: "total" }) + " : "}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalbet}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalwin}
                    </td>
                    <td className="member-cell col-2">
                      {reportState.transactionReportLists.totalprofit}
                    </td>
                  </tr>
                )}
              </tfoot> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
