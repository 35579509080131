import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const platformSettingState = {
  gameSuspendList: null,
  suspendActive: null,
  bankAccountList: null,
  addBankAccountList: null,
  deleteBankAccountList: null,
  contactList: null,
  addContactList: null,
  deleteContactList: null,
  paymentMethodList: null,
  updatePaymentMethod: null,
  companyGameList: null,
  updateCompanyGameList: null,
  telegramList: null,
  addTelegramList: null,
  deleteTelegramList: null,
  downloadList: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const platformSettingSlice = createSlice({
  name: "platformSetting",
  initialState: platformSettingState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },

    gameSuspendListFetch: (state, action) => {
      state.actionsLoading = false;
      state.gameSuspendList = action.payload.data ?? {};
    },
    suspendActiveFetch: (state, action) => {
      state.actionsLoading = false;
      state.suspendActive = action.payload.data ?? {};
    },
    bankListFetch: (state, action) => {
      state.actionsLoading = false;
      state.bankAccountList = action.payload.data ?? {};
    },
    addBankListFetch: (state, action) => {
      state.actionsLoading = false;
      state.addBankAccountList = action.payload.data ?? {};
    },
    deleteBankListFetch: (state, action) => {
      state.actionsLoading = false;
      state.deleteBankAccountList = action.payload.data ?? {};
    },
    contactListFetch: (state, action) => {
      state.actionsLoading = false;
      state.contactList = action.payload.data ?? {};
    },
    addContactListFetch: (state, action) => {
      state.actionsLoading = false;
      state.addContactList = action.payload.data ?? {};
    },
    deleteContactListFetch: (state, action) => {
      state.actionsLoading = false;
      state.deleteContactList = action.payload.data ?? {};
    },
    paymentMethodListFetch: (state, action) => {
      state.actionsLoading = false;
      state.paymentMethodList = action.payload.data ?? {};
    },
    updatePaymentMethodFetch: (state, action) => {
      state.actionsLoading = false;
      state.updatePaymentMethod = action.payload.data ?? {};
    },
    companyGameListFetch: (state, action) => {
      state.actionsLoading = false;
      state.companyGameList = action.payload.data ?? {};
    },
    updateCompanyGameListFetch: (state, action) => {
      state.actionsLoading = false;
      state.updateCompanyGameList = action.payload.data ?? {};
    },
    telegramListFetch: (state, action) => {
      state.actionsLoading = false;
      state.telegramList = action.payload.data ?? {};
    },
    addTelegramListFetch: (state, action) => {
      state.actionsLoading = false;
      state.addTelegramList = action.payload.data ?? {};
    },
    deleteTelegramListFetch: (state, action) => {
      state.actionsLoading = false;
      state.deleteTelegramList = action.payload.data ?? {};
    },
    downloadListFetch: (state, action) => {
      state.actionsLoading = false;
      state.downloadList = action.payload.data ?? {};
    },
  },
});
